import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme
} from '@mui/material';
import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import './feature-file-upload.css';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { useDropzone } from 'react-dropzone';
import LoadingButton from '@mui/lab/LoadingButton';
import cdnService from '../../services/cdnService';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import 'react-image-crop/dist/ReactCrop.css';

const AvatarWrapper = styled(Card)(
  ({ theme }) => `
    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: ${0};
    margin-left: ${0};

     img{
    object-fit: fill;
}
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};
      box-shadow: ${theme.colors.shadows.error};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.error.dark};
      }
    }
`
);

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const AvatarCroppedImg = styled(Avatar)(
  ({ theme }) => `
       img{
    object-fit: fill!important;
}
  `
);

const initCrop: Crop = {
  x: 5,
  y: 5,
  width: 80,
  height: 80,
  unit: '%'
};

function FeatureImage(props: {
  onChange: (fileName: string) => void;
  value?: string;
}) {
  const theme = useTheme();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [src, setSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [croppedImgSrc, setCroppedImgSrc] = useState('');

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  //const [value, setValue] = useState(
  //    props.uploadedFileName || ''
  //);

  const width = 200;
  const height = 200;

  useEffect(() => {}, []);

  useEffect(() => {
    setCroppedImgSrc(cdnService.toImageUrl(props.value));
  }, [props.value]);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setCrop(undefined);

    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setSrc(reader.result?.toString() || '');
      setCrop(initCrop);
    });
    reader.readAsDataURL(file);
  }, []);

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    maxSize: 5242880,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg']
      //   'image/heic': ['.heic'],
      //   'image/heif': ['.heif']
    },
    onDropAccepted
  });

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    }
  }, [completedCrop]);

  const onCropConfirm = async () => {
    if (previewCanvasRef.current.toDataURL() === 'data:,') {
      enqueueSnackbar('Please select a valid image file (PNG, JPG)', {
        variant: 'error'
      });
      setSrc('');
      setCroppedImgSrc('');
      return;
    }
    setIsUploading(true);
    previewCanvasRef.current.toBlob(function (blob) {
      uploadFileToCloud('test.png', blob).then((blobName) => {
        setCroppedImgSrc(previewCanvasRef.current.toDataURL());
        props.onChange(blobName);
      });
    }, 'image/png');
  };

  const onImageRemove = () => {
    setSrc('');
    setCroppedImgSrc('');
    props.onChange('');
  };

  let progressTimerRef: any;

  const startTimer = () => {
    setIsUploading(true);
    setProgress(0);
    progressTimerRef = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 90 ? prevProgress : prevProgress + 10
      );
    }, 200);
  };

  const uploadFileToCloud = async (
    fileName: string,
    blob: any
  ): Promise<string> => {
    let blobName = '';
    try {
      const getExt = (fileName: string): string => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
      };

      startTimer();
      const ext = getExt(fileName);

      blobName = 'img-' + new Date().getTime() + (ext ? '.' + ext : '');

      const containerClient = cdnService.getContainerClient(
        cdnService.imageContainerName
      );
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      await blockBlobClient.uploadBrowserData(blob, {
        onProgress: (o) => {
          //    total +=   o.loadedBytes;
        }
      });
      setProgress(100);

      setTimeout((o) => {
        setIsUploading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t(`Failed to upload image`), { variant: 'error' });
      setIsUploading(false);
    }
    clearInterval(progressTimerRef);
    return blobName;
  };

  return (
    <Box sx={{ p: 0, width: width, textAlign: 'center' }}>
      {!src && !croppedImgSrc && (
        <Box
          {...getRootProps()}
          component="div"
          className="feature-file-upload"
        >
          <input {...getInputProps()} />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <div>
              <p>Drag and drop your file here or browse</p>
              <AvatarCloud variant="rounded">
                <CloudUploadTwoToneIcon />
              </AvatarCloud>
            </div>
          </label>
        </Box>
      )}
      {src && !croppedImgSrc && (
        <>
          {' '}
          <div>
            {' '}
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            >
              <img
                style={{ height: height, width: width }}
                ref={imgRef}
                src={src}
              />
            </ReactCrop>
          </div>
          {isUploading && <CircularProgressWithLabel value={progress} />}
          {!isUploading && (
            <ButtonGroup
              variant="contained"
              size="small"
              aria-label="outlined button group"
            >
              <Button
                onClick={() => {
                  setSrc('');
                  setCroppedImgSrc('');
                }}
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={() => onCropConfirm()}
                loading={false}
                loadingIndicator="Loading..."
                variant="outlined"
              >
                Confirm
              </LoadingButton>
            </ButtonGroup>
          )}
        </>
      )}
      {!!completedCrop && (
        <canvas
          id="ddd"
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height
          }}
        />
      )}
      {croppedImgSrc && (
        <AvatarWrapper>
          <AvatarCroppedImg
            variant="rounded"
            sx={{ height: height, width: width }}
            src={croppedImgSrc}
          />
          <ButtonUploadWrapper onClick={onImageRemove}>
            <label htmlFor="icon-button-file">
              <IconButton component="span" color="primary">
                <DeleteIcon />
              </IconButton>
            </label>
          </ButtonUploadWrapper>
        </AvatarWrapper>
      )}
    </Box>
  );
}

function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    clearTimeout(t);
    return () => {};
  }, deps);
}

export default FeatureImage;
