import { Box, Grid, LinearProgress, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
        flex-grow: 1;
        margin-right: ${theme.spacing(1)};
`
);

const RootWrapper = styled(Box)(
  () => `
    flex: 1;
`
);

function PageHeader(props: {
  isEdit: boolean;
  businessId: string;
  onBack: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();

  return (
    <RootWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {t('Add information about your business')}
                {/* {props.isEdit ? 'Edit Contact' : t('New Contact')} */}
              </Typography>
              <Typography variant="subtitle2">
                {props.isEdit
                  ? t(
                      'Fill in the fields below to complete your business profile'
                    )
                  : t(
                      'Fill in the fields below to complete your business profile'
                    )}
              </Typography>
              {/* <LinearProgressWrapper
                value={57}
                color="primary"
                variant="determinate"
              />
              <Typography variant="h4" color="text.primary">
                57%
              </Typography> */}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {/* <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            startIcon={<ArrowBackTwoToneIcon />}
            onClick={props.onBack}
            variant="contained"
          >
            {t('Go back')}
          </Button> */}
          {/* <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              // ml: 1,
              flexGrow: 1
            }}
          >
            <LinearProgressWrapper
              value={57}
              color="primary"
              variant="determinate"
            />
            <Typography variant="h4" color="text.primary">
              57%
            </Typography>
          </Box> */}
        </Grid>
      </Grid>
    </RootWrapper>
  );
}

export default PageHeader;
