import { GoogleOAuthProvider } from '@react-oauth/google';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { AuthProvider } from 'src/ui-bloom/contexts/JWTAuthContext';
import { SidebarProvider } from 'src/ui-bloom/contexts/SidebarContext';
import ScrollTop from 'src/ui-bloom/hooks/useScrollTop';
import { GOOGLE_OAUTH_CLIENT_ID } from './services/endpoints';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
