import QuizIcon from '@mui/icons-material/Quiz';

import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Field, FieldArray, getIn } from 'formik';

import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const CardContentWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};
  `
);

const QuectionDiv = styled('div')(
  ({ theme }) => `
     width:100%;
     font-weight:700;
    line-height:17.6px;
    font-size:14px;
    `
);
function VenueFaq(props: { accountRefData: RefData }) {
  const amenitiesNames = props.accountRefData.amenities.map((o) => o.value);
  const questions = props.accountRefData.faqTemplate;
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <AvatarCloud variant="rounded">
              <QuizIcon />
            </AvatarCloud>
          }
          title={t('Faq')}
          subheader={
            'Kindly furnish information regarding your services. Incorporate responses to commonly asked questions about your business to offer customers a clearer comprehension of your offerings prior to reaching out.'
          }
        />

        <Divider />

        <CardContentWrapper
          sx={{
            mx: 3,
            mb: 3,
            height: 344
          }}
        >
          <Scrollbar>
            <FieldArray name="services.promotions">
              {(arrayHelpers) => {
                return (
                  <Grid container spacing={3}>
                    {arrayHelpers.form.values?.services?.faqs.length > 0 &&
                      arrayHelpers.form.values?.services?.faqs.map(
                        (item, index) => (
                          <Grid key={item.code} xs={12} item>
                            <Stack spacing={2} margin={2}>
                              <Typography
                                lineHeight={1}
                                gutterBottom
                                variant="body1"
                              >
                                Q {index + 1}.&nbsp; {item.text}
                              </Typography>
                              <Grid
                                container
                                direction="row"
                                spacing={4}
                                marginTop={4}
                                marginLeft={1}
                                marginBottom={4}
                              >
                                {item.type === 'radio' && (
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name={item.code}
                                      value={item.answer}
                                      onChange={(event) => {
                                        arrayHelpers.form.setFieldValue(
                                          `services.faqs.${index}.isAnswered`,
                                          true
                                        );
                                        arrayHelpers.form.setFieldValue(
                                          `services.faqs.${index}.answer`,
                                          event.target.value
                                        );
                                        // arrayHelpers.form.handleChange(event);
                                      }}
                                    >
                                      {item.options.map((op) => (
                                        <FormControlLabel
                                          key={op.code}
                                          value={op.code}
                                          control={<Radio />}
                                          label={op.value}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                )}
                                {item.type === 'input' && (
                                  <Grid item md={4} xs={12}>
                                    <TextField
                                      fullWidth
                                      autoFocus={false}
                                      id={'siteFee'}
                                      required={false}
                                      name={`services.faqs.${index}.answer`}
                                      value={item.answer}
                                      label={'Fee'}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                      onChange={(event) => {
                                        arrayHelpers.form.setFieldValue(
                                          `services.faqs.${index}.isAnswered`,
                                          (event.target.value || '').trim()
                                            ?.length
                                            ? true
                                            : false
                                        );
                                        arrayHelpers.form.setFieldValue(
                                          `services.faqs.${index}.answer`,
                                          event.target.value
                                        );
                                        // arrayHelpers.form.handleChange(event);
                                      }}
                                      error={
                                        getIn(
                                          arrayHelpers.form.touched,
                                          `services.faqs.${index}.answer`
                                        ) &&
                                        getIn(
                                          arrayHelpers.form.errors,
                                          `services.faqs.${index}.answer`
                                        )
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        getIn(
                                          arrayHelpers.form.touched,
                                          `services.faqs.${index}.answer`
                                        ) &&
                                        getIn(
                                          arrayHelpers.form.errors,
                                          `services.faqs.${index}.answer`
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                                {item.type === 'checkbox' &&
                                  item.options.map((op, innerIndex) => (
                                    <Grid key={op.code} item md={3} xs={12}>
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name={op.code}
                                          checked={item?.answer?.[op.code]}
                                          value={op.value}
                                          onChange={(event) => {
                                            arrayHelpers.form.setFieldValue(
                                              `services.faqs.${index}.isAnswered`,
                                              true
                                            );
                                            arrayHelpers.form.setFieldValue(
                                              `services.faqs.${index}.answer`,
                                              {
                                                ...(item?.answer || {}),
                                                [op.code]: event.target.checked
                                              }
                                            );
                                            // arrayHelpers.form.handleChange(
                                            //   event
                                            // );
                                          }}
                                        />
                                        {op.value}
                                      </label>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Stack>
                          </Grid>
                        )
                      )}
                  </Grid>
                );
              }}
            </FieldArray>
          </Scrollbar>
        </CardContentWrapper>
      </Card>
    </>
  );
}

export default VenueFaq;
