import { Contact } from 'src/services/apiService/response-models';
import { Metadata } from 'src/services/query-client/dto';
import { BUSINESS_CATEGORIES, BUSINESS_TYPE } from '../../constant';

export interface AccountSignUpResponse {
  businessName: string;
  email: string;
  password: string;
  location: string;
  terms: boolean;
  businessType: string;
  businessCategory: string;
  contactNumber: string;
}

export interface BusinessCategoryResponse {
  id: string;
  businessType: string;
  displayName: string;
  code: string;
  iconUrl: boolean;
  isActive: boolean;
}

export interface BusinessType {
  displayName: string;
  code: string;
}

export enum UserRoles {
  SAdmin = 'sadmin',
  Admin = 'admin',
  Manager = 'manager',
  member = 'member'
}

export interface User {
  id: string;
  userName: string;
  displayName?: string;
  isActive: boolean;
  businessAccountId: string;
  roles: { roleName: UserRoles }[];
}

export interface UserContext {
  userName: string;
  appName: string;
  businessAccountId: string;
  user: User;
  isApiClient: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isSadmin: boolean;
}

export interface BusinessDetails {
  aboutServicesDescRichText: string;

  contactPersonName: string;

  contactPersonEmail: string;

  contactPersonMobile: number;

  contactPersonPhone: number;

  website: string;
}

export interface Address {
  zipCode: string;

  city: string;

  country: string;

  address: string;

  googleMapLocation: string;
}

export interface DisplayImages {
  url: string;

  title: string;

  desc: string;

  showAsMainPhoto: boolean;
}

export interface BusinesService {
  businessDetails: BusinessDetails;
  address: Address;
  displayImages: DisplayImages[];
  prefferedVendors: any[];
  events: any[];
  availibility: any[];
  socialMediaUrls: string[];

  //
  // cateringInfo: {
  //   menus: string;
  // };
  //
  // venueInfo: {
  //   guestCapacity: number;
  // };
}

export interface Business {
  id: string;
  businessName: string;
  location: string;
  businessCategory: BUSINESS_CATEGORIES;
  businessType: BUSINESS_TYPE;
  phone: string;
  email: string;
  services: BusinesService[];
  // managedBy: string;
  metadata?: Metadata;
  // subscription?: Subscription;
  // accountSetting?: AccountSetting;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface UserAccountEntity {
  context: UserContext;
  user: User;
  business: Business;
}

export interface UserContext {
  userName: string;
  appName: string;
  clientAccountId: string;
  user: User;
  isApiClient: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isSadmin: boolean;
}

export interface CodeItem {
  code?: string;
  value?: string;
}

export interface ServiceFaq {
  code: string;
  type: string;
  text: string;
  label?: string;
  options: CodeItem[];
  isAnswered: boolean;
  answer: any;
}

export interface RefData {
  businessTypes: CodeItem[];
  businessCategories: CodeItem[];
  amenities: CodeItem[];
  faqTemplate: ServiceFaq[];
  eventTypes: CodeItem[];
  leadLiCyStages: CodeItem[];
  nameTitles: CodeItem[];
  contactGroups: CodeItem[];
}

export enum AddressTypes {
  None = 'None',
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Home = 'Home',
  Office = 'Office'
}

export interface AddressContact {
  addressType: AddressTypes;
  addressLine?: string;
  addressLine2?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  zip?: string;
  geoLat?: string;
  geoLong?: string;
  isActive?: boolean;
  clientAccountId?: number;
}

export interface Lead {
  id?: string;
  contact?: Contact;
  // profile?: Profile;
  // teamMember?: TeamMember;
  contactFirstName?: string;
  contactMiddleName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactNumber?: string;
  contactAddress?: string;
  teamMemberId?: string;
  tags?: string[];
  remark?: string;
  formData?: Metadata;
  metadata?: Metadata;
  liCyStage?: string;
  archievedLiCyStages?: string[];
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface Review {
  id?: string;
  contact?: Contact;
  // profile?: Profile;
  // teamMember?: TeamMember;
  contactFirstName?: string;
  contactMiddleName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactNumber?: string;
  contactAddress?: string;
  teamMemberId?: string;
  tags?: string[];
  remark?: string;
  rating?: number;
  formData?: Metadata;
  metadata?: Metadata;
  liCyStage?: string;
  archievedLiCyStages?: string[];
  createdBy?: string;
  createdOn?: Date;
  updatedBy?: string;
  updatedOn?: Date;
}
