import {
  Autocomplete,
  Card,
  CardHeader,
  Divider,
  Grid,
  TextField,
  useTheme
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';

function GroupInfo(props: { accountRefData: RefData }) {
  const groupNames = props.accountRefData.contactGroups.map((o) => o.value);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader title={t('Group/Tag')} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={1}>
        <Grid item xs={12} md={6}>
          <Field name="groupTags">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                multiple
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(event: any, newValues: string[] | null) => {
                  const vals = newValues.filter((o) => o.trim().length);
                  setFieldValue(field.name, vals);
                  setFieldTouched(field.name, true);
                }}
                options={groupNames}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or Select contact group...')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GroupInfo;
