import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  CardActions,
  Grid,
  IconButton,
  Slide,
  Tooltip
} from '@mui/material';
import { useState } from 'react';
import Label from '../ui-bloom/components/Label';

export default function DeleteCardAction(props: {
  onDelete: () => void;
  disabled?: boolean;
}) {
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);

  if (isDeleteRequested) {
    return (
      <Slide
        direction="right"
        in={isDeleteRequested}
        mountOnEnter
        unmountOnExit
      >
        <CardActions>
          <Label>Are you sure?</Label>
          <Button
            size="small"
            onClick={props.onDelete}
            variant="outlined"
            color="error"
          >
            Delete
          </Button>
          <Tooltip title="Cancel delete">
            <IconButton
              onClick={() => {
                setIsDeleteRequested(false);
              }}
              aria-label="delete"
            >
              <CancelPresentationTwoToneIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Slide>
    );
  }

  return (
    <CardActions>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <IconButton
            disabled={props.disabled}
            color="error"
            aria-label="delete"
            type="button"
            onClick={() => {
              setIsDeleteRequested(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </CardActions>
  );
}
