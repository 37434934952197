import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { UserAccountEntity } from 'src/services/ezzyEvents/apiService/responseModel';
import { AppState } from '../app-state';

const initialState: AppState = {
  sidebarOpen: false,
  isDrawerOpen: false
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    //setBrand(
    //    state: AppState,
    //    action: PayloadAction<{ brand: Restaurant }>
    //) {
    //    const { brand } = action.payload;
    //    state.brand = _.cloneDeep(brand);
    //},
    setUser(
      state: AppState,
      action: PayloadAction<{ user: UserAccountEntity }>
    ) {
      const { user } = action.payload;
      state.user = { ...user };
    },

    openSidebar(state: AppState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: AppState): void {
      state.sidebarOpen = false;
    },
    openDrawerPanel(state: AppState): void {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: AppState): void {
      state.isDrawerOpen = false;
    }
  }
});

const { actions, reducer } = slice;

export const {
  setUser,
  closeSidebar,
  openSidebar,
  openDrawerPanel,
  closeDrawerPanel
} = actions;
export default reducer;
