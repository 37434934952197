import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { default as EditTwoToneIcon } from '@mui/icons-material/EditTwoTone';

import RestaurantMenuRoundedIcon from '@mui/icons-material/RestaurantMenuRounded';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DeleteCardAction from 'src/components/DeleteCardAction';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import CreateEditMenuDialog from './Dialog/create-edit-menu';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(['all'])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const CardActions = styled(Box)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
  z-index: 7;
  display: flex;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);
const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

function Menus(props: { accountRefData: RefData }) {
  const groupNames = [];
  // props.accountRefData.contactGroups.map((o) => o.value);
  const [fieldMenus, metaMenus, helpersMenus] = useField('services.menus');
  const [fieldMenuItem, metaMenuItem, helpersMenuItem] = useField('menuItem');
  const [visible, setVisible] = useState(false);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <CreateEditMenuDialog
        menuItem={fieldMenuItem.value}
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersMenuItem.setValue({});
        }}
        onSave={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersMenuItem.setValue({});
        }}
        editIndex={editIndex}
      />
      <Card>
        <CardHeader
          avatar={
            <AvatarCloud variant="rounded">
              <RestaurantMenuRoundedIcon />
            </AvatarCloud>
          }
          title={t('Menus')}
          subheader={t(
            'Please distribute menus showcasing the variety of cuisines available, serving styles, beverage selections, and additional offerings.'
          )}
          action={
            <Button
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={() => {
                setOpen(true);
              }}
            >
              {'Add'}
            </Button>
          }
        />
        <Divider />

        <Box p={3}>
          <FieldArray name="services.menus">
            {(arrayHelpers) => {
              return (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  marginTop={1}
                  marginBottom={4}
                >
                  {arrayHelpers.form.values?.services?.menus.length == 0 && (
                    <Grid item xs={12} sm={12} md={2} lg={4}>
                      <Tooltip arrow title={t('Click to add a new menu')}>
                        <CardAddAction
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <CardActionArea
                            sx={{
                              px: 1
                            }}
                          >
                            <CardContent>
                              <AvatarAddWrapper>
                                <AddTwoToneIcon fontSize="large" />
                              </AvatarAddWrapper>
                            </CardContent>
                          </CardActionArea>
                        </CardAddAction>
                      </Tooltip>
                    </Grid>
                  )}
                  {arrayHelpers.form.values?.services?.menus.length > 0 &&
                    arrayHelpers.form.values?.services?.menus.map(
                      (mn, index) => (
                        <Grid key={mn.name} item md={4} xs={12}>
                          <CardWrapper>
                            <Box
                              sx={{
                                position: 'relative',
                                zIndex: '2'
                              }}
                            >
                              <Box
                                px={2}
                                pt={2}
                                display="flex"
                                justifyContent="space-between"
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    p: 0.5
                                  }}
                                  onClick={() => {
                                    helpersMenuItem.setValue(mn);
                                    setEditIndex(index);

                                    setOpen(true);
                                  }}
                                >
                                  <EditTwoToneIcon />
                                </IconButton>
                              </Box>
                              <Box
                                p={2}
                                display="flex"
                                justifyContent={'center'}
                              >
                                <Box>
                                  <Box>
                                    <Typography variant="button">
                                      <b>{mn.name}</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        pt: 1
                                      }}
                                      variant="h6"
                                    >
                                      Price: ${mn.price}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider />
                              <DeleteCardAction
                                onDelete={() => {
                                  const arr = [...fieldMenus.value];
                                  arr.splice(index, 1);
                                  helpersMenus.setValue(arr);
                                }}
                              />
                            </Box>
                          </CardWrapper>
                        </Grid>
                      )
                    )}
                </Grid>
              );
            }}
          </FieldArray>
        </Box>
      </Card>
    </>
  );
}

export default Menus;
