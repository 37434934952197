import { AccountTypes } from 'src/services/apiService/response-models';
import { UserAccountEntity } from 'src/services/ezzyEvents/apiService/responseModel';
import {
  MenuItems,
  menuItemsForAdmin
} from 'src/ui-bloom/layouts/BoxedSidebarLayout/Sidebar/SidebarMenu/items';

const restrictedMenuByRole = {
  [AccountTypes.Business]: {
    member: ['leads', 'contacts', 'shopping'],
    admin: [
      'leads',
      'contacts',
      'shopping',
      'business-cards',
      'users',
      'settings'
    ]
  },
  [AccountTypes.Individual]: ['leads', 'contacts', 'shopping']
};

export const getMenuItemsByUser = (user: UserAccountEntity): MenuItems[] => {
  return menuItemsForAdmin;
};
