import axios, { AxiosResponse } from 'axios';
import { QueryClient } from 'react-query';
import { Contact } from '../apiService/response-models';
import { ApiUrls } from '../endpoints';
import { Lead, RefData, Review } from '../ezzyEvents/apiService/responseModel';
import { USER_TYPE } from '../ezzyEvents/constant';
import { Business, ContactDto, LeadDto, ReviewDto } from './dto';

export const QKEY = {
  Profiles: 'profiles',
  Contacts: 'contacts',
  Leads: 'leads',
  Reviews: 'reviews',
  Members: 'members',
  Users: 'users',
  Teams: 'teams',
  RefData: 'ref-data'
};

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

export const getContacts = (): Promise<AxiosResponse<Contact[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/all';
  return axios.get(dataUrl);
};

export const deleteContactById = (
  id: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/' + id;
  return axios.delete(dataUrl);
};

export const getContactById = (id: string): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact/' + id;
  return axios.get(dataUrl);
};

export const saveContact = (
  contact: ContactDto,
  id?: string
): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.base + '/api/v1/contact';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, contact);
  else return axios.post(dataUrl, contact);
};

export const getRefData = (): Promise<AxiosResponse<RefData>> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/ref-data';
  return axios.get(dataUrl);
};

export const getBusinessById = (
  id: string
): Promise<AxiosResponse<Business>> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/business/' + id;
  return axios.get(dataUrl);
};

export const saveBusiness = (
  business: Business,
  id?: string
): Promise<AxiosResponse<Business>> => {
  const dataUrl = ApiUrls.ezzyEventsApi + '/business';
  if (id && id.trim().length > 0)
    return axios.put(dataUrl + `/${id}`, business);
  else return axios.post(dataUrl, business);
};

export const saveLead = (
  lead: LeadDto,
  id?: string
): Promise<AxiosResponse<Lead>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, lead);
  else return axios.post(dataUrl, lead);
};

export const getLeads = (): Promise<AxiosResponse<Lead[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead';
  return axios.get(dataUrl);
};

export const getLeadById = (id: string): Promise<AxiosResponse<Lead>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/' + id;
  return axios.get(dataUrl);
};

export const deleteLeadById = (id: string): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/lead/' + id;
  return axios.delete(dataUrl);
};

export const getReviewById = (id: string): Promise<AxiosResponse<Review>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review/' + id;
  return axios.get(dataUrl);
};
export const saveReview = (
  review: ReviewDto,
  id?: string
): Promise<AxiosResponse<Lead>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review';
  if (id && id.trim().length > 0) return axios.put(dataUrl + `/${id}`, review);
  else return axios.post(dataUrl, review);
};
export const getReviews = (): Promise<AxiosResponse<Review[]>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review';
  return axios.get(dataUrl);
};
export const deletReviewById = (
  id: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.base + '/api/v1/review/' + id;
  return axios.delete(dataUrl);
};

export const loginWithGoogleSignon = (jwt: string) => {
  return axios.post(ApiUrls.ezzyEventsApi + '/account/google-login', {
    jwt,
    userType: USER_TYPE.BUSINESS_USER
  });
};

export const getGoogleUser = (jwt: string) => {
  return axios.post(ApiUrls.ezzyEventsApi + '/account/get-google-user', {
    jwt,
    userType: USER_TYPE.BUSINESS_USER
  });
};
