import SaveIcon from '@mui/icons-material/Save';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, FieldProps, Formik, useField } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FeatureImageField from 'src/components/FeatureImage/FeatureImageField';
import { MyMapComponent } from 'src/components/google-map';
import { CodeItem } from 'src/services/ezzyEvents/apiService/responseModel';
import { Events } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import * as Yup from 'yup';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function CreateEditEventsDialog(props: {
  eventItem?: Events;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  editIndex: number;
  eventTypes: CodeItem[];
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();
  const [openAuto, setOpenAuto] = React.useState(false);
  const { user } = useSelector((state: RootState) => state.app);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(props.editIndex >= 0);
  const [fieldEvents, metaEvents, helpersEvents] = useField('services.events');
  const [model, setModel] = useState<Events | null>(null);

  const [latLong, setLatLong] = useState(null);

  useEffect(() => {
    setOpen(props.isOpen);
    setModel(props.eventItem);
    setLatLong(props.eventItem?.gmapUrl?.latLng);
  }, [props.isOpen, props.eventItem]);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE
    });

  console.log(placePredictions);
  // const loading = placePredictions && placePredictions.length === 0;

  const onSave = async (updatedModel: Events) => {
    helpersEvents.setValue([...(fieldEvents?.value || []), updatedModel]);
    let isSuccess = true;
    try {
      setIsSaveInProgress(true);

      enqueueSnackbar(
        t(`Events was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Event`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {isEdit ? t(`Edit Event`) : t('Add Event')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update event')
                : t('Fill in the fields below to create a new event')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              name: model?.name || '',
              description: model?.description || '',
              descriptionRich: model?.descriptionRich || '',
              city: model?.city,
              address: model?.address,
              gmapUrl: model?.gmapUrl,
              startDate: model?.startDate || '',
              endDate: model?.endDate || '',
              type: model?.type || '',
              imageUrl: model?.imageUrl || '',
              isActive: isEdit
                ? model?.isActive === true
                  ? true
                  : false
                : true
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('The field is required')),
              type: Yup.string().required(t('The field is required')),

              city: Yup.string().required(t('The field is required')),
              gmapUrl: Yup.object().required(t('The field is required')),
              address: Yup.string().required(t('The field is required')),
              startDate: Yup.date().required(t('The field is required')),
              endDate: Yup.date()
                .min(
                  Yup.ref('startDate'),
                  "end date can't be before start date"
                )
                .required(t('The field is required')),
              descriptionRich: Yup.string()
                .max(2550)
                .required(t('The field is required')),
              imageUrl: Yup.string().required(t('The field is required'))
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                const arr = [...(fieldEvents?.value || [])];
                if (props.editIndex >= 0) {
                  arr[props.editIndex] = _values;
                } else {
                  arr.push(_values);
                }

                helpersEvents.setValue(arr);
                var isSuccess = true;
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                props.onSave();
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              getFieldHelpers
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={t('Name of the event')}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="address-state-select-filled-label">
                              Type of Event
                            </InputLabel>
                            <Select
                              error={Boolean(touched.city && errors.city)}
                              name="type"
                              value={values.type}
                              labelId="pronouns-select"
                              id="pronouns-select"
                              onChange={handleChange}
                            >
                              <MenuItem value={''}>{'Select'}</MenuItem>
                              {props.eventTypes.map((o) => (
                                <MenuItem key={o.code} value={o.code}>
                                  {o.value}
                                </MenuItem>
                              ))}
                            </Select>
                            {touched.type && (errors.type ? true : false) && (
                              <FormHelperText
                                error={
                                  touched.type && (errors.type ? true : false)
                                }
                              >
                                {errors.type}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Field name="startDate">
                              {({ field, form, meta }: FieldProps) => (
                                <DesktopDatePicker
                                  minDate={new Date()}
                                  label="Start date"
                                  inputFormat="MM/dd/yyyy"
                                  onChange={(newValue: Date | null) =>
                                    form.setFieldValue(field.name, newValue)
                                  }
                                  value={field.value || null}
                                  renderInput={(params) => (
                                    <TextField
                                      error={errors.startDate ? true : false}
                                      helperText={
                                        errors?.startDate && errors.startDate
                                      }
                                      fullWidth
                                      {...params}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Field name="endDate">
                              {({ field, form, meta }: FieldProps) => (
                                <DesktopDatePicker
                                  minDate={new Date()}
                                  label="End date"
                                  inputFormat="MM/dd/yyyy"
                                  onChange={(newValue: Date | null) =>
                                    form.setFieldValue(field.name, newValue)
                                  }
                                  value={field.value || null}
                                  renderInput={(params) => (
                                    <TextField
                                      error={Boolean(
                                        touched.endDate && errors.endDate
                                      )}
                                      helperText={
                                        errors?.endDate && errors.endDate
                                      }
                                      fullWidth
                                      {...params}
                                    />
                                  )}
                                />
                              )}
                            </Field>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            label={t('City')}
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            error={Boolean(touched.address && errors.address)}
                            fullWidth
                            helperText={touched.address && errors.address}
                            label={t('Address')}
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Field name="descriptionRich">
                            {({ field, form, meta }: FieldProps) => (
                              <>
                                <EditorWrapper>
                                  <ReactQuill
                                    modules={{
                                      clipboard: {
                                        matchVisual: false
                                      }
                                    }}
                                    placeholder="Add your event details"
                                    value={field.value}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) => {
                                      const text = editor.getText();
                                      const textRich = editor.getHTML();
                                      form.setFieldValue(field.name, textRich);
                                      form.setFieldValue('description', text);
                                    }}
                                  />
                                </EditorWrapper>
                                {meta.touched &&
                                  (meta.error ? true : false) && (
                                    <FormHelperText
                                      error={
                                        meta.touched &&
                                        (meta.error ? true : false)
                                      }
                                    >
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </Field>
                          <FormHelperText>Description</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card sx={{ textAlign: 'center', m: 1 }}>
                            <CardHeader title="Event Banner" />
                            <CardContent sx={{ p: 0 }}>
                              <FeatureImageField name="imageUrl" />
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field name="gmapUrl">
                            {({
                              field,
                              form: {
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                                setFieldError
                              },
                              meta
                            }: FieldProps) => (
                              <>
                                <Autocomplete
                                  id="asynchronous-demo"
                                  sx={{
                                    m: 0
                                  }}
                                  open={openAuto}
                                  value={field.value}
                                  onChange={async (
                                    event: any,
                                    newValues: any | null
                                  ) => {
                                    setLatLong(null);
                                    setFieldTouched(field.name, true);
                                    const results = await geocodeByPlaceId(
                                      newValues.place_id
                                    );
                                    const latLng = await getLatLng(results[0]);
                                    setLatLong(latLng);
                                    setFieldValue(field.name, {
                                      ...newValues,
                                      latLng
                                    });
                                  }}
                                  onOpen={() => {
                                    setOpenAuto(true);
                                  }}
                                  onClose={() => {
                                    setOpenAuto(false);
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.description === value.description
                                  }
                                  getOptionLabel={(option) =>
                                    option.description
                                  }
                                  options={placePredictions}
                                  loading={isPlacePredictionsLoading}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t('Google Map Address')}
                                      onChange={(evt) =>
                                        getPlacePredictions({
                                          input: evt.target.value
                                        })
                                      }
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {isPlacePredictionsLoading ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        )
                                      }}
                                    />
                                  )}
                                />
                                {meta.touched &&
                                  (meta.error ? true : false) && (
                                    <FormHelperText
                                      error={
                                        meta.touched &&
                                        (meta.error ? true : false)
                                      }
                                    >
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {
                            <MyMapComponent
                              value={latLong}
                              isMarkerShown
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE}`}
                              loadingElement={
                                <div style={{ height: `100%` }} />
                              }
                              containerElement={
                                <div style={{ height: `200px` }} />
                              }
                              mapElement={<div style={{ height: `100%` }} />}
                            />
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                name="isActive"
                                // disabled={!isEdit}
                                color={isEdit ? 'primary' : 'secondary'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.isActive}
                              />
                            }
                            label={t('Is Active')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default CreateEditEventsDialog;
