import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';

export const MyMapComponent = withScriptjs(
  withGoogleMap((props: { isMarkerShown: boolean; value: any }) => (
    <GoogleMap
      defaultZoom={15}
      center={{ ...(props?.value || { lat: -34.397, lng: 150.644 }) }}
      defaultCenter={{ ...(props?.value || { lat: -34.397, lng: 150.644 }) }}
      options={{ streetViewControl: false, mapTypeControl: false }}
    >
      {props?.value && (
        <Marker position={{ lat: props.value.lat, lng: props.value.lng }} />
      )}
    </GoogleMap>
  ))
);
