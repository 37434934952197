import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid
} from '@mui/material';
import { Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  sendEmailVerificationCode,
  verifyEmail
} from 'src/services/ezzyEvents/apiService';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';

const EmailVerifyStep = () => {
  const { t } = useTranslation();
  const isMountedRef = useRefMounted();
  const [isEmailVerificationCodeSent, setIsEmailVerificationCodeSent] =
    useState(false);
  const [isEmailVerifiedField, meta, helpers] = useField('isEmailVerified');
  const [verificationCodeField] = useField('verificationCode');
  const [emailField] = useField('email');
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleEmailVerification = useCallback(
    async (
      callback: (
        hasError: boolean,
        data: {
          isVerified: boolean;
          isVarificationCodeExpired: boolean;
          msg?: string;
        }
      ) => void
    ) => {
      if (isMountedRef.current) {
        try {
          const res = await verifyEmail(
            emailField.value,
            verificationCodeField.value
          );
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message
            ? ex.message
            : `Failed to verify verification code`;
          callback(true, {
            isVerified: false,
            isVarificationCodeExpired: false,
            msg
          });
        }
      }
    },
    [isMountedRef, verificationCodeField.value]
  );
  const handleVerifyEmail = () => {
    setLoading(true);
    handleEmailVerification((hasError, data) => {
      setLoading(false);
      if (hasError) {
        enqueueSnackbar(data.msg, { variant: 'error' });
      } else {
        if (data.isVerified) {
          enqueueSnackbar('Email verified successfully', {
            variant: 'success'
          });
          helpers.setValue(true);
        } else {
          enqueueSnackbar('Invalid verification code', {
            variant: 'error'
          });
        }
      }
    });
  };

  const handleEmailVerificationCodeSend = useCallback(
    async (callback: (hasError: boolean) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await sendEmailVerificationCode(emailField.value);
          callback(false);
        } catch (ex) {
          const msg = ex.message
            ? ex.message
            : `Failed to send email verification code`;
          callback(true);
        }
      }
    },
    [isMountedRef]
  );
  // make a api call on first render to send email verification code
  useEffect(() => {
    setLoading(true);
    handleEmailVerificationCodeSend((hasError) => {
      setIsEmailVerificationCodeSent(!hasError);
      setLoading(false);
    });
  }, [handleEmailVerificationCodeSend]);

  return (
    <Box p={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Field
            fullWidth
            name="verificationCode"
            component={TextField}
            label={t('Verification code')}
            helperText="Verification code sent to your registered email."
            placeholder={t('Write your verification code here...')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <>
            <Button
              disabled={
                !isEmailVerificationCodeSent ||
                isEmailVerifiedField.value === true
              }
              variant="contained"
              onClick={handleVerifyEmail}
              startIcon={loading ? <CircularProgress size="1rem" /> : null}
              color={isEmailVerifiedField.value === true ? 'success' : 'info'}
            >
              {isEmailVerifiedField.value === true ? 'Verified' : 'Verify'}
            </Button>
            {Boolean(meta.touched && meta.error) && (
              <FormHelperText error>{meta.error}</FormHelperText>
            )}
          </>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EmailVerifyStep;
