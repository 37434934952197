import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
  styled
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from 'src/services/apiService';
import Logo from 'src/ui-bloom/components/LogoSign';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      overflow: auto;
      flex: 1;
  `
);

function ForgotPassword() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Forgot password @ KiEngage</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth="md"
        >
          <Logo />
          <Card
            sx={{
              mt: 3,
              pt: 4
            }}
          >
            <Box px={4}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                {t('Reset password for KiEngage')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t(
                  'Fill in the email below to reset password for your account.'
                )}
              </Typography>
            </Box>

            <CardContent>
              <Box>
                <Formik
                  initialValues={{
                    email: ''
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email(t('Email is not valid'))
                      .required(t('Email is required'))
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      const response = await forgotPassword(values.email);
                      if (response.status === 200) {
                        enqueueSnackbar(
                          t(
                            'You will receive email if there is account setup with this email.'
                          ),
                          {
                            variant: 'success'
                          }
                        );
                        navigate(`/app/reset-password/${values.email}`);
                      }
                    } catch (err) {
                      const message = parseToApiErrorMessage(
                        err,
                        `Failed to sent email for reset password`
                      );
                      enqueueSnackbar(message, {
                        variant: 'error'
                      });
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ errors, handleSubmit, isSubmitting, touched }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box sx={{ mb: 3 }}>
                        <Field
                          fullWidth
                          name="email"
                          component={TextField}
                          label={t('Email')}
                          placeholder={t('Write your email here...')}
                        />
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <Button
                          size="large"
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate('/app/login')}
                          sx={{
                            mx: 1
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          color="primary"
                          disabled={
                            isSubmitting || (errors.email && touched.email)
                          }
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{
                            mx: 1,
                            px: 3
                          }}
                        >
                          {t('Submit')}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export default ForgotPassword;
