import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  styled
} from '@mui/material';
import { Field, FieldProps, useField } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyMapComponent } from 'src/components/google-map';
import usStates from 'src/utility/usStates';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);
const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

function ContactInfo(props: { isEdit: boolean }) {
  const { t }: { t: any } = useTranslation();
  const [
    fieldGoogleMapLocation,
    metaGoogleMapLocation,
    helpersGoogleMapLocation
  ] = useField('services.address.googleMapLocation');

  const [currentTab, setCurrentTab] = useState<string>('contact');
  const [latLong, setLatLong] = useState(fieldGoogleMapLocation.value.latLng);
  const [openAuto, setOpenAuto] = React.useState(false);

  const tabs = [
    { value: 'contact', label: t('Contact') },
    { value: 'address', label: t('Address') },
    { value: 'social', label: t('Social networks') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };
  // useEffect(() => {
  //   setLatLong();
  // }, []);
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE
    });

  return (
    <Card>
      <CardHeader
        avatar={
          <AvatarCloud variant="rounded">
            <ContactPageRoundedIcon />
          </AvatarCloud>
        }
        title={t('Contact Details')}
        subheader={t(
          'Lead notifications and updates from ezzyevents will be sent to this contact.'
        )}
      />
      <Divider />
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Divider />
      <Box p={3}>
        {currentTab === 'contact' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="services.businessDetails.contactPersonName"
                variant="outlined"
                label={t('Name')}
                placeholder={t('Enter name here ...')}
                component={FmTextFiled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="services.businessDetails.contactPersonEmail"
                variant="outlined"
                label={t('Email')}
                placeholder={t('Enter email here ...')}
                component={FmTextFiled}
              />
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Field fullWidth name="countryDialCodeWork">
                {(props) => <CountryCodeSlector {...props} />}
              </Field>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="services.businessDetails.contactPersonPhone"
                variant="outlined"
                label={t('Phone number (Work)')}
                placeholder={t('Enter phone here ...')}
                component={FmTextFiled}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Field fullWidth name="countryDialCodePersonal">
                {(props) => <CountryCodeSlector {...props} />}
              </Field>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="services.businessDetails.contactPersonMobile"
                variant="outlined"
                label={t('Mobile number (Personal)')}
                placeholder={t('Enter number here ...')}
                component={FmTextFiled}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                fullWidth
                name="services.businessDetails.website"
                variant="outlined"
                label={t('Website')}
                placeholder={t('Enter website here ...')}
                component={FmTextFiled}
              />
            </Grid>
          </Grid>
        )}
        {currentTab === 'address' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.address.addressLine"
                variant="outlined"
                label={t('Address line')}
                placeholder={t('Enter address here ...')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.address.addressLine2"
                variant="outlined"
                label={t('Address line 2')}
                placeholder={t('Enter address2 here ...')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="services.address.countryCode">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="country">{t('Country')}</InputLabel>
                    <Select
                      label={t('country')}
                      inputProps={{
                        name: 'country'
                      }}
                      value={field.value}
                      onChange={(_event) => {
                        form.setFieldValue(field.name, _event.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select...</em>
                      </MenuItem>
                      {[{ code: 'usa', value: 'United States' }].map(
                        ({ value, code }) => (
                          <MenuItem key={code} value={code}>
                            {t(value)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="services.address.stateCode">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="state">{t('State')}</InputLabel>
                    <Select
                      label={t('state')}
                      inputProps={{
                        name: 'state'
                      }}
                      value={field.value}
                      onChange={(_event) => {
                        form.setFieldValue(field.name, _event.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select...</em>
                      </MenuItem>
                      {usStates.map(({ value, code }) => (
                        <MenuItem key={code} value={code}>
                          {t(value)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.address.zipCode"
                variant="outlined"
                label={t('Zip code')}
                placeholder={t('Enter zip here ...')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Field
                component={FmTextFiled}
                fullWidth
                name="services.address.googleMapLocation"
                variant="outlined"
                label={t('Google map url')}
                placeholder={t('Enter google map url here ...')}
              /> */}
              <Field name="services.address.googleMapLocation">
                {({
                  field,
                  form: {
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError
                  },
                  meta
                }: FieldProps) => (
                  <>
                    <Autocomplete
                      id="asynchronous-demo"
                      sx={{
                        m: 0
                      }}
                      open={openAuto}
                      value={field.value}
                      onChange={async (event: any, newValues: any | null) => {
                        setLatLong(null);
                        setFieldTouched(field.name, true);
                        const results = await geocodeByPlaceId(
                          newValues.place_id
                        );
                        const latLng = await getLatLng(results[0]);
                        setLatLong(latLng);
                        setFieldValue(field.name, {
                          ...newValues,
                          latLng
                        });
                      }}
                      onOpen={() => {
                        setOpenAuto(true);
                      }}
                      onClose={() => {
                        setOpenAuto(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                      }
                      getOptionLabel={(option) =>
                        option?.description || 'Type Google address'
                      }
                      options={placePredictions}
                      loading={isPlacePredictionsLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('Google Map Address')}
                          onChange={(evt) =>
                            getPlacePredictions({
                              input: evt.target.value
                            })
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isPlacePredictionsLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                    {meta.touched && (meta.error ? true : false) && (
                      <FormHelperText
                        error={meta.touched && (meta.error ? true : false)}
                      >
                        {meta.error}
                      </FormHelperText>
                    )}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={12}>
              {
                <MyMapComponent
                  value={latLong}
                  isMarkerShown
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `200px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              }
            </Grid>
          </Grid>
        )}
        {currentTab === 'social' && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.socialMediaUrls.instagram"
                variant="outlined"
                label={t('Instagram')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.socialMediaUrls.facebook"
                variant="outlined"
                label={t('Facebook')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.socialMediaUrls.pinterest"
                variant="outlined"
                label={t('Pinterest')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PinterestIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="services.socialMediaUrls.twitter"
                variant="outlined"
                label={t('Twiter')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
}

export default ContactInfo;
