import { useEffect, useRef, useState } from 'react';

import { CSVLink } from 'react-csv';
import Footer from 'src/ui-bloom/components/Footer';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import ErrorPanel from 'src/components/error-panel';
import { getLeads } from 'src/services/query-client';
import Results from './Results';

function LeadList() {
  const { enqueueSnackbar } = useSnackbar();
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [teamDataRefreshTriggerId, setTeamDataRefreshTriggerId] = useState('');
  const refLink = useRef(null);

  const {
    data: { data: leads = [] } = {},
    isError,
    error,
    isLoading,
    refetch
  } = useQuery('leads', getLeads);

  const onRefreshData = () => {
    setTeamDataRefreshTriggerId(Date.now() + '');
  };

  const handleExportLeads = () => {
    refLink.current?.link?.click();
  };

  useEffect(() => {}, []);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader refreshData={onRefreshData} />

        <CSVLink
          ref={refLink}
          data={leads}
          filename={'leads.csv'}
          headers={[
            {
              key: 'fullName',
              label: 'fullName'
            },
            {
              key: 'email',
              label: 'email'
            },
            {
              key: 'descriptionRich',
              label: 'descriptionRich'
            },
            {
              key: 'leadNumber',
              label: 'leadNumber'
            }
          ]}
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {isError && (
            <ErrorPanel
              title="failed to load data"
              message={JSON.stringify(error)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Results
            leads={leads}
            isLoading={isLoading}
            onDeleteSuccess={() => refetch()}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default LeadList;
