import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  useTheme
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Field, FieldProps, useField } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);
const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function BusinessDetails(props: { accountRefData: RefData }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [fieldBusinessType, metaBusinessType, helpersBusinessType] =
    useField('businessType');
  const [fieldBusinessCategory, metaBusinessCategory, helpersBusinessCategory] =
    useField('businessCategory');
  return (
    <Card>
      <CardHeader
        avatar={
          <AvatarCloud variant="rounded">
            <InfoRoundedIcon />
          </AvatarCloud>
        }
        title={t('Business Details')}
      />
      <Divider />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
          <Grid item md={12} xs={12}>
            <Field
              fullWidth
              name="businessName"
              component={FmTextFiled}
              label="Business name *"
              placeholder={t('business name here...')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="businessType-select-filled-label">
                Business Type *
              </InputLabel>
              <Select
                disabled={true}
                name="businessType"
                labelId="businessType-select"
                id="businessType-select"
                value={fieldBusinessType.value}
                onChange={(_event) => {
                  helpersBusinessType.setValue(_event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Select...</em>
                </MenuItem>
                {props.accountRefData.businessTypes.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.value}
                  </MenuItem>
                ))}
              </Select>
              {metaBusinessType.touched &&
                (metaBusinessType.error ? true : false) && (
                  <FormHelperText
                    error={
                      metaBusinessType.touched &&
                      (metaBusinessType.error ? true : false)
                    }
                  >
                    {metaBusinessType.error}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="businessCategory-select-filled-label">
                Business Category *
              </InputLabel>
              <Select
                disabled={true}
                name="businessCategory"
                labelId="businessCategory-select"
                id="businessCategory-select"
                value={fieldBusinessCategory.value}
                onChange={(_event) => {
                  helpersBusinessCategory.setValue(_event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Select...</em>
                </MenuItem>
                {props.accountRefData.businessCategories.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.value}
                  </MenuItem>
                ))}
              </Select>
              {metaBusinessCategory.touched &&
                (metaBusinessCategory.error ? true : false) && (
                  <FormHelperText
                    error={
                      metaBusinessCategory.touched &&
                      (metaBusinessCategory.error ? true : false)
                    }
                  >
                    {metaBusinessCategory.error}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Field name="services.businessDetails.aboutServicesDescRichText">
              {({ field, form, meta }: FieldProps) => (
                <>
                  <EditorWrapper>
                    <ReactQuill
                      modules={{
                        clipboard: {
                          matchVisual: false
                        }
                      }}
                      placeholder="Describe your business and services"
                      value={field.value}
                      onChange={(content, delta, source, editor) => {
                        const text = editor.getText();
                        const textRich = editor.getHTML();
                        form.setFieldValue(field.name, textRich);
                        form.setFieldValue('description', text);
                      }}
                    />
                  </EditorWrapper>
                  {meta.touched && (meta.error ? true : false) && (
                    <FormHelperText
                      error={meta.touched && (meta.error ? true : false)}
                    >
                      {meta.error}
                    </FormHelperText>
                  )}
                </>
              )}
            </Field>
            <FormHelperText>
              Share unique, descriptive information about your business in order
              to convert couples and improve your ranking across top search
              engines. Please do not include any contact information in this
              section.
            </FormHelperText>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Card>
  );
}

export default BusinessDetails;
