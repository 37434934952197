import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Field, FieldProps, Formik, useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FeatureImageField from 'src/components/FeatureImage/FeatureImageField';
import { Promotions } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import * as Yup from 'yup';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function CreateEditPromotionsDialog(props: {
  promoItem?: Promotions;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  editIndex: number;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(props.editIndex >= 0);
  const [fieldMenus, metaMenus, helpersMenus] = useField('services.promotions');
  const [model, setModel] = useState<Promotions | null>(null);

  useEffect(() => {
    setOpen(props.isOpen);
    setModel(props.promoItem);
  }, [props.isOpen, props.promoItem]);

  const onSave = async (updatedModel: Promotions) => {
    helpersMenus.setValue([...(fieldMenus?.value || []), updatedModel]);
    let isSuccess = true;
    try {
      setIsSaveInProgress(true);

      enqueueSnackbar(
        t(`Menu was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Menu`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {isEdit ? t(`Edit Promotion`) : t('Add Promotion')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update promotion')
                : t('Fill in the fields below to create a new promotion')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              name: model?.name || '',
              description: model?.description || '',
              descriptionRich: model?.descriptionRich || '',

              discount: model?.discount || '',
              type: model?.type || '',
              imageUrl: model?.imageUrl || '',
              validTillDate: model?.validTillDate || null,
              isActive: isEdit
                ? model?.isActive === true
                  ? true
                  : false
                : true
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('The field is required')),
              descriptionRich: Yup.string()
                .max(2550)
                .required(t('The field is required')),
              discount: Yup.number()
                .typeError(t('should be a number'))
                .when('type', {
                  is: (val) => val == 'discount',
                  then: Yup.number().required(t('The field is required'))
                }),
              imageUrl: Yup.string().required(t('The field is required'))
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                const arr = [...fieldMenus?.value];
                if (props.editIndex >= 0) {
                  arr[props.editIndex] = _values;
                } else {
                  arr.push(_values);
                }

                helpersMenus.setValue(arr);
                var isSuccess = true;
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                props.onSave();
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={t('Name of the promotion')}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="filled" fullWidth>
                            <InputLabel id="address-state-select-filled-label">
                              Type of promotion
                            </InputLabel>
                            <Select
                              name="type"
                              value={values.type}
                              labelId="pronouns-select"
                              id="pronouns-select"
                              onChange={handleChange}
                            >
                              <MenuItem value={''}>{'Select'}</MenuItem>
                              {[
                                { code: 'Discount', value: 'discount' },
                                { code: 'Gift', value: 'gift' },
                                { code: 'Offer', value: 'offer' }
                              ].map((o) => (
                                <MenuItem key={o.code} value={o.value}>
                                  {o.code}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {values.type === 'discount' && (
                          <Grid item xs={12}>
                            <TextField
                              error={Boolean(
                                touched.discount && errors.discount
                              )}
                              fullWidth
                              helperText={touched.discount && errors.discount}
                              label={t('Discount(%)')}
                              name="discount"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.discount}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Field name="descriptionRich">
                            {({ field, form, meta }: FieldProps) => (
                              <>
                                <EditorWrapper>
                                  <ReactQuill
                                    modules={{
                                      clipboard: {
                                        matchVisual: false
                                      }
                                    }}
                                    placeholder="Add your menu details"
                                    value={field.value}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) => {
                                      const text = editor.getText();
                                      const textRich = editor.getHTML();
                                      form.setFieldValue(field.name, textRich);
                                      form.setFieldValue('description', text);
                                    }}
                                  />
                                </EditorWrapper>
                                {meta.touched &&
                                  (meta.error ? true : false) && (
                                    <FormHelperText
                                      error={
                                        meta.touched &&
                                        (meta.error ? true : false)
                                      }
                                    >
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </Field>
                          <FormHelperText>Description</FormHelperText>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <TextField
                            multiline
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label={t('Description')}
                            rows={3}
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Field name="validTillDate">
                              {({ field, form, meta }: FieldProps) => (
                                <DesktopDatePicker
                                  minDate={new Date()}
                                  label="Valid Till"
                                  inputFormat="MM/dd/yyyy"
                                  onChange={(newValue: Date | null) =>
                                    form.setFieldValue(field.name, newValue)
                                  }
                                  value={field.value || null}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              )}
                            </Field>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                name="isActive"
                                // disabled={!isEdit}
                                color={isEdit ? 'primary' : 'secondary'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.isActive}
                              />
                            }
                            label={t('Is Active')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Card sx={{ textAlign: 'center', m: 1 }}>
                        <CardHeader title="Banner Image" />
                        <CardContent sx={{ p: 0 }}>
                          <FeatureImageField name="imageUrl" />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default CreateEditPromotionsDialog;
