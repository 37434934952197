import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/ui-bloom/layouts/BaseLayout';
import baseRoutes from './base';

import BoxedSidebarLayout from '../ui-bloom/layouts/BoxedSidebarLayout';

import CreateBusiness from 'src/contents/business/create-business';
import CreateContact from 'src/contents/contact/create-contact';
import ContactList from 'src/contents/contact/list';
import CreateLead from 'src/contents/lead/create-lead';
import LeadList from 'src/contents/lead/list';
import NotFound from 'src/contents/not-found';
import CreateReview from 'src/contents/review/create-review';
import ReviewList from 'src/contents/review/list';
import SignUpLogout from 'src/pages/sign-up/signup-logout';

const router: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app/sign-up',
    element: <SignUpLogout />
  },

  // Extended Sidebar Layout

  {
    path: 'app',
    element: <BoxedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" />
      },

      {
        path: 'home',
        children: [
          {
            path: '',
            element: <CreateBusiness />
          }
        ]
      },
      {
        path: 'contact',
        children: [
          {
            path: 'list',
            element: <ContactList />
          },
          {
            path: 'new',
            element: <CreateContact />
          },
          {
            path: 'edit/:id',
            element: <CreateContact />
          },

          {
            path: 'upload',
            element: () => null
          }
        ]
      },
      {
        path: 'lead',
        children: [
          {
            path: 'list',
            element: <LeadList />
          },
          {
            path: 'new',
            element: <CreateLead />
          },
          {
            path: 'edit/:id',
            element: <CreateLead />
          }
        ]
      },
      {
        path: 'review',
        children: [
          {
            path: 'list',
            element: <ReviewList />
          },
          {
            path: 'detail/:id',
            element: <CreateReview />
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: 'user/list',
            element: () => null
          }
        ]
      },
      {
        path: 'setting',
        element: () => null
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'app/map-nfc-code',
    element: () => null
  },
  {
    path: 'app/delete-account',
    element: () => null
  }
];

export default router;
