import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  styled,
  useTheme
} from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  appQueryClient,
  getBusinessById,
  getRefData,
  saveBusiness
} from 'src/services/query-client';
import { Business } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import { isValidZipRegExp, phoneRegExp, urlRegExp } from 'src/utility/reg-exes';
import * as Yup from 'yup';

import { BUSINESS_CATEGORIES } from 'src/services/ezzyEvents/constant';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import VenueFaq from '../faq/venue-faq';
import AmentiesInfo from './AmentiesInfo';
import BusinessDetails from './BusinessDetails';
import ContactInfo from './ContactInfo';
import Menus from './Menu';
import Photos from './Photos';
import PriceRange from './PriceRange';
import Sidebar from './Sidebar';
import Events from './events';
import Promotions from './promotions';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

function CreateBusiness() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<string>('businessDetails');

  const tabs = [
    { value: 'businessDetails', label: t('Business details') },
    { value: 'contact', label: t('Contact Info') },
    { value: 'photos', label: t('Photos') },
    { value: 'menus', label: t('Menus') },
    { value: 'promotions', label: t('Promotions') },
    { value: 'faq', label: t('Faq') },
    { value: 'events', label: t('Events') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };
  const {
    user: { businessAccountId }
  } = useSelector((state: RootState) => state.app.user);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(
    Boolean(businessAccountId && businessAccountId.length)
  );

  // const { user } = useSelector((state: RootState) => state.app);

  // Queries
  const {
    data: { data: accountRefData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery('ref-data', getRefData);

  const {
    data: { data: business } = {},
    isLoading,
    refetch: refetchById,
    isFetching
  } = useQuery(
    ['business', businessAccountId],
    (k) => getBusinessById(businessAccountId),
    {
      enabled: isEdit
    }
  );

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: Business) => saveBusiness(e, businessAccountId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  useEffect(() => {}, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: Business) => {
    enqueueSnackbar(
      t(`Business was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/contact/edit/' + updatedModel.id);
    else {
      appQueryClient.setQueryData(
        ['business', businessAccountId],
        updatedModel
      );
      refetchById();
    }
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/contact/list`);
  };
  const sidebarContent = (
    <Scrollbar>
      <Sidebar isSubmit={isSubmit} />
    </Scrollbar>
  );

  if (isLoading || isFetching || isRefDataLoading) {
    return <Loading />;
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: businessAccountId,
          businessName: business?.businessName || '',
          businessType: business?.businessType || '',
          businessCategory: business?.businessCategory || '',

          services: {
            businessDetails: {
              aboutServicesDescRichText:
                business?.services[0]?.businessDetails
                  ?.aboutServicesDescRichText || '',
              contactPersonName:
                business?.services[0]?.businessDetails?.contactPersonName || '',
              contactPersonEmail:
                business?.services[0]?.businessDetails?.contactPersonEmail ||
                business?.email,
              contactPersonPhone:
                business?.services[0]?.businessDetails?.contactPersonPhone ||
                '',
              contactPersonMobile:
                business?.services[0]?.businessDetails?.contactPersonMobile ||
                business?.phone,
              website: business?.services[0]?.businessDetails?.website || ''
            },
            address: {
              addressLine: business?.services[0]?.address?.addressLine || '',
              addressLine2: business?.services[0]?.address?.addressLine2 || '',
              countryCode: business?.services[0]?.address?.countryCode || '',
              stateCode: business?.services[0]?.address?.stateCode || '',
              zipCode: business?.services[0]?.address?.zipCode || '',
              googleMapLocation:
                business?.services[0]?.address?.googleMapLocation || {}
            },
            socialMediaUrls: {
              twitter: business?.services[0]?.socialMediaUrls?.twitter || '',
              instagram:
                business?.services[0]?.socialMediaUrls?.instagram || '',
              facebook: business?.services[0]?.socialMediaUrls?.facebook || '',
              pinterest: business?.services[0]?.socialMediaUrls?.pinterest || ''
            },
            displayImages: business?.services[0]?.displayImages || [],
            menus: business?.services[0]?.menus || [],
            promotions: business?.services[0]?.promotions || [],
            events: business?.services[0]?.events || [],
            activeDiscount: business?.services[0]?.activeDiscount || '',
            amenities: business?.services[0]?.amenities || [],
            guestCapacity: business?.services[0]?.guestCapacity || '',
            priceRange: business?.services[0]?.priceRange || {},
            faqs:
              business?.services[0]?.faqs ||
              accountRefData.faqTemplate[business?.businessCategory]
          }
        }}
        validationSchema={Yup.object().shape({
          businessName: Yup.string()
            .max(250)
            .required(t('The field is required')),
          businessType: Yup.string().required(t('The field is required')),
          businessCategory: Yup.string().required(t('The field is required')),
          services: Yup.object().shape({
            businessDetails: Yup.object().shape({
              aboutServicesDescRichText: Yup.string().required(
                t('The field is required')
              ),
              contactPersonName: Yup.string()
                .max(250)
                .required(t('The field is required')),
              contactPersonEmail: Yup.string()
                .email()
                .max(250)
                .required(t('The field is required')),
              contactPersonMobile: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required(t('The field is required')),
              contactPersonPhone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required(t('The field is required')),
              website: Yup.string()
                .matches(urlRegExp, 'Website url is not valid')
                .required(t('The field is required'))
            }),
            address: Yup.object().shape({
              googleMapLocation: Yup.object().required(
                t('The field is required')
              ),
              zipCode: Yup.string().matches(
                isValidZipRegExp,
                'zipcode is not valid'
              )
            }),
            socialMediaUrls: Yup.object().shape({
              twitter: Yup.string().matches(urlRegExp, 'url is not valid'),
              instagram: Yup.string().matches(urlRegExp, 'url is not valid'),
              facebook: Yup.string().matches(urlRegExp, 'url is not valid'),
              pinterest: Yup.string().matches(urlRegExp, 'url is not valid')
            }),
            displayImages: Yup.array().of(
              Yup.object().shape({
                url: Yup.string().required(t('The field is required')),
                title: Yup.string().required(t('The field is required')),
                desc: Yup.string()
              })
            ),
            menus: Yup.array().of(
              Yup.object().shape({
                name: Yup.string()
                  .max(100)
                  .required(t('The field is required')),
                desc: Yup.string().required(t('The field is required')),
                price: Yup.number()
                  .typeError(t('should be a number'))
                  .positive()
                  .required(t('The field is required'))
              })
            ),
            faqs: Yup.array().of(
              Yup.object()
                .shape({
                  type: Yup.string()
                })
                .when((values, schema) => {
                  if (values.type === 'input') {
                    return schema.shape({
                      answer: Yup.number()
                        .typeError(t('should be a number'))
                        .positive(t('should be greater than zero'))
                    });
                  }
                })
            ),
            guestCapacity: Yup.number()
              .typeError(t('should be a number'))
              .positive(t('should be greater than zero')),
            priceRange: Yup.object().shape(
              {
                min: Yup.number()
                  .typeError(t('should be a number'))
                  .positive()
                  .when('max', {
                    is: (val) => val,
                    then: Yup.number()
                      .positive()
                      .max(
                        Yup.ref('max'),
                        t('The value cant be more than max price')
                      )
                      .required(t('The field is required'))
                  }),
                max: Yup.number()
                  .typeError(t('should be a number'))
                  .positive()
                  .when('min', {
                    is: (val) => val,
                    then: Yup.number()
                      .positive()
                      .min(
                        Yup.ref('min'),
                        t('The value cant be less than min price')
                      )
                      .required(t('The field is required'))
                  })
              },
              [['min', 'max']]
            )
          })
        })}
        onSubmit={async (
          { ..._values },
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          const updatedModel: Business = {
            ...business,
            ..._values,
            services: [_values.services]
          } as Business;

          save(updatedModel);

          setStatus({ success: true });
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
          errors
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        businessId={businessAccountId}
                        onBack={handleBack}
                      />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {console.log(errors)}
                    <Grid item>
                      {!business.shouldListed && (
                        <Alert severity="warning">
                          {' '}
                          {t(
                            'Please make sure to add basic details with contact info and some photos of your services to be eligible for listing'
                          )}
                        </Alert>
                      )}
                      {!isEmpty(errors) && (
                        <Alert severity="error">
                          {' '}
                          {t('Please fill the required field.')}
                        </Alert>
                      )}
                    </Grid>
                    <Card>
                      <Divider />
                      <TabsContainerWrapper>
                        <Tabs
                          onChange={handleTabsChange}
                          value={currentTab}
                          variant="scrollable"
                          scrollButtons="auto"
                          textColor="primary"
                          indicatorColor="primary"
                        >
                          {tabs
                            .filter(
                              (t) =>
                                [
                                  BUSINESS_CATEGORIES.VENDOR_CATERERS,
                                  BUSINESS_CATEGORIES.VENUE_BANQUET_HALLS,
                                  BUSINESS_CATEGORIES.VENUE_HOTELS
                                ].includes(business.businessCategory) ||
                                t.value !== 'menus'
                            )
                            .map((tab) => (
                              <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                              />
                            ))}
                        </Tabs>
                      </TabsContainerWrapper>
                      <Divider />
                    </Card>
                  </Grid>
                  {currentTab === 'businessDetails' && (
                    <>
                      <Grid item xs={12}>
                        <BusinessDetails accountRefData={accountRefData} />
                      </Grid>
                      <Grid item xs={12}>
                        <AmentiesInfo accountRefData={accountRefData} />
                      </Grid>
                      <Grid item xs={12}>
                        <PriceRange accountRefData={accountRefData} />
                      </Grid>
                    </>
                  )}
                  {currentTab === 'contact' && (
                    <Grid item xs={12}>
                      <ContactInfo isEdit={isEdit} />
                    </Grid>
                  )}
                  {currentTab === 'photos' && (
                    <Grid item xs={12}>
                      <Photos accountRefData={accountRefData} />
                    </Grid>
                  )}
                  {currentTab == 'menus' && (
                    <Grid item xs={12}>
                      <Menus accountRefData={accountRefData} />
                    </Grid>
                  )}
                  {currentTab == 'promotions' && (
                    <Grid item xs={12}>
                      <Promotions accountRefData={accountRefData} />
                    </Grid>
                  )}
                  {currentTab == 'faq' && (
                    <Grid item xs={12}>
                      <VenueFaq accountRefData={accountRefData} />
                    </Grid>
                  )}
                  {currentTab == 'events' && (
                    <Grid item xs={12}>
                      <Events accountRefData={accountRefData} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            component={RouterLink}
                            variant="outlined"
                            to={`/${
                              location.pathname.split('/')[1]
                            }/contact/list`}
                          >
                            Cancel
                          </Button>
                          <Tooltip
                            placement="top"
                            arrow
                            title={
                              currentTab === 'businessDetails'
                                ? t(
                                    'Please make sure to add basic details with contact info and some photos of your services to be eligible for listing'
                                  )
                                : 'Save'
                            }
                          >
                            <Button
                              sx={{ ml: 2 }}
                              type="submit"
                              onClick={() => onSaveClick()}
                              variant="contained"
                              startIcon={
                                isSubmitting || isSaving ? (
                                  <CircularProgress size="1rem" />
                                ) : (
                                  <SaveIcon />
                                )
                              }
                              disabled={isLoading || isSubmitting || isSaving}
                            >
                              Save
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                {/* <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile> */}
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                {/* <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper> */}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

function Loading() {
  return (
    <>
      <LinearProgress />
      {[1, 2, 3, 4, 5].map((o) => (
        <Card key={o} sx={{ margin: 2 }}>
          <CardHeader
            title={
              <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
            }
          />
          <Divider />
          <CardContent
            sx={{
              background: '#f9fafb'
            }}
          >
            <Box p={1}>
              <Grid container spacing={3}>
                {[1, 2, 3, 4, 5].map((e) => (
                  <Grid key={e} item xs>
                    <Link href="#" underline="none">
                      <Box
                        p={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Paper elevation={3} sx={{ width: 100 }}>
                          <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={100}
                          />
                        </Paper>
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
}

export default CreateBusiness;
