import { Metadata } from '@azure/storage-blob';
import { Dictionary } from 'lodash';
import { AddressTypes } from 'src/services/ezzyEvents/apiService/responseModel';

export enum AllConstant {
  DEFAULT_TEAM_NAME = 'Default team'
}

export interface ClientAccount {
  id: number;
  name: string;
  description: string;
  tenantCode: string;
  email: string;
  contactNumber: string;
  accountType: AccountTypes;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export enum AccountTypes {
  Business = 'Business',
  Individual = 'Individual',
  Any = 'Any'
}

export enum CardTypes {
  Business = 'Business',
  Individual = 'Individual',
  Service = 'Service',
  All = 'All'
}

export enum EmailTypes {
  Personal = 'Personal',
  Work = 'Work',
  Alternate = 'Alternate'
}

export interface Address {
  addressType: AddressTypes;
  addressLine?: string;
  addressLine2?: string;
  city?: string;
  cityCode?: string;
  state?: string;
  stateCode?: string;
  country?: string;
  countryCode?: string;
  zip?: string;
  geoLat?: string;
  geoLong?: string;
  isActive?: boolean;
  clientAccountId?: number;
}
export interface SocialMediaProvider {
  id: number;
  name: string;
  category: string;
  logo: string;
  url: string;
  isActive: boolean;
}

export interface SocialMediaLink {
  id: number;
  socialMediaProviderId: number;
  link: string;
}

export interface Contact {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  title?: string;
  fullName?: string;
  email?: string;
  altEmail?: string;
  contactNumber?: string;
  altContactNumber?: string;
  pronouns?: string;
  description?: string;
  descriptionRich?: string;
  imageUrl?: string;
  website?: string;
  dateOfBirth?: Date;
  groupTags?: string[];
  addresses?: Address[];
  linkedIn?: string;
  facebook?: string;
  twitter?: string;
  whatsApp?: string;
  externalUrl?: string;
  metadata?: Metadata;
  liCyStage?: CodeItem;
  archievedLiCyStages?: CodeItem[];
  source?: CodeItem;
  isDeleted?: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface GeoLoc {
  id: number;
  lat: string;
  lng: string;
}

export interface Accreditation {
  id: number;
  name: string;
  description: string;
  logoUrl?: string;
}

export interface BusinessCategory {
  id: number;
  name: string;
  description: string;
  featureImage: string;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
}

export interface CodeItem {
  codeId: number;
  value: string;
  codeType?: string;
}

//card instance
export interface DigitalCard {
  id: number;
  label: string;
  name?: string;
  isActiveCard?: boolean;
  description: string;
  cardType: CardTypes;
  parentId: number;
  fields: CardFieldValue[];
  qrCode?: string;
  theme?: string;
  nfc?: NfcInfo;
  isOnline: boolean;
  isActive: boolean;
  cardAppItems: CardAppItem[];
  nfcCodeMaps?: NfcCodeMap[];
}

export interface NfcInfo {
  code: string;
}

export interface QRInfo {
  code: string;
  imageUrl: string;
}

export interface CardFieldValue {
  id: string;
  fieldId: number;
  order: number;
  fieldName: string;
  formFieldValues: FormFieldValue[];
}

export interface FormFieldValue {
  name: string;
  value: string;
}

export interface KeyValueItem {
  key: string;
  value: string;
}
export interface DigitalCardField {
  id: number;
  cardType: CardTypes;
  categoryId: number;
  categoryName: string;
  name: string;
  displayName: string;
  description?: string;
  icon?: string;
  order?: number;
  formFields?: CardFormField[];
  multiple?: boolean;
  valueFieldName?: string;
  valueDescriptor?: string;
  titleDescriptor?: string;
}

export interface CardFormField {
  id: number;
  digitalCardFieldId: number;
  name: string;
  displayName: string;
  helpText?: string;
  icon?: string;
  isRequired?: boolean;
  dataType: FieldDataType;
  props: string | any;
  options: string | any;
  validationKey?: string;
  defaultValue?: string;
  order?: number;
}

export enum FieldDataType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Time = 'Time',
  Link = 'Link',
  TextArea = 'TextArea',
  Radio = 'Radio',
  File = 'File',
  Image = 'Image',
  Button = 'Button',
  DropDown = 'DropDown',
  DropDownMultiSelection = 'DropDownMultiSelection',
  ComboBox = 'ComboBox',
  RichTextArea = 'RichTextArea',
  Password = 'Password',
  Checkbox = 'Checkbox'
}

export interface CardFieldCategory {
  id: number;
  name: string;
  displayName: string;
  description: string;
  icon: string;
  order: number;
  isActive: boolean;
  cardType: CardTypes;
  digitalCardFields: DigitalCardField[];
}

export enum CardFieldCategoryNames {
  PersonalDetails = 'PersonalDetails',

  BusinessDetails = 'BusinessDetails',

  Contact = 'Contact',

  Social = 'Social',

  Communication = 'Communication',

  Business = 'Business',

  Payment = 'Payment',

  AppStore = 'AppStore'
}

export enum CardFieldNames {
  FullName = 'FullName',

  BusinessName = 'BusinessName',

  Headline = 'Headline',

  CompanyName = 'CompanyName',

  Department = 'Department',

  Accreditation = 'Accreditation',

  Email = 'Email',

  ContactNumber = 'ContactNumber',

  Website = 'Website',

  JobTitle = 'JobTitle',

  Logo = 'Logo',

  DateStarted = 'DateStarted',

  DateOfBirth = 'DateOfBirth',

  ProfilePic = 'ProfilePic',

  CoverPhoto = 'CoverPhoto',

  Link = 'Link',

  Address = 'Address',

  Twitter = 'Twitter',

  Instagram = 'Instagram',

  LinkedIn = 'LinkedIn',

  Facebook = 'Facebook',

  YouTube = 'YouTube',

  Snapchat = 'Snapchat',

  Twitch = 'Twitch',

  WhatsApp = 'WhatsApp',

  Signal = 'Signal',

  Discord = 'Discord',

  Skype = 'Skype',

  Telegram = 'Telegram',

  GitHub = 'GitHub',

  Calendly = 'Calendly',

  PayPal = 'PayPal',

  CashApp = 'CashApp',

  AppleStore = 'AppleStore',

  AppStore = 'AppStore',

  MediaPlayer = 'MediaPlayer',

  ImageSlider = 'ImageSlider',

  GoogleReview = 'GoogleReview'
}

export interface User {
  id: number;
  employeeId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isAdmin: boolean;
  isActive: boolean;
  roles: { roleName: string }[];
}

export interface LogInResult {
  token: string;
}

export interface Team {
  id: number;
  name: string;
  description?: string;
  imageUrl?: string;
  isActive: boolean;
  createdBy: number;
  createdOn: Date;
  updatedBy?: number;
  updatedOn?: Date;
}
export interface CodeMaster {
  id: number;
  codeType: string;
  code: string;
  value: string;
}

export interface NameItem {
  id: number;
  name: string;
}

export interface Employee {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName?: string;
  email: string;
  altEmail?: string;
  contactNumber?: string;
  altContactNumber?: string;
  description?: string;
  descriptionRich?: string;
  pronouns?: string;
  imageUrl?: string;
  website?: string;
  teams: NameItem[];
  departments?: CodeItem[];
  jobTitle?: CodeItem;
  dateOfBirth?: Date;
  isActive: boolean;
  socialMediaLinks?: SocialMediaLink[];
  addresses?: Address[];
  userRoles: string[];
}

export interface DigitalCardListItem {
  digitalCardId: number;
  label: string;
  description: string;
  cardType: CardTypes;
  parentId: number;
  parentName?: string;
  theme?: string;
  isActiveCard: boolean;
  isActive: boolean;
  fields?: CardFieldValue[];
}

export interface DigitalCardAppItem extends DigitalCardListItem {
  cardAppItems: CardAppItem[];
}

export interface CardApp {
  id: number;
  name: string;
  description: string;
  displayName: string;
  icon: string;
  url: string;
  qrCode: string;
  isActive: boolean;
  isExternal: boolean;
}

export interface CardAppItem {
  id: number;
  digitalCardId: number;
  cardName: string;
  cardAppId: number;
  appConfig: Dictionary<string>;
  cardApp?: CardApp;
  qrUrl?: string;
}

export interface ContactUploadItem {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  description: string;
  pronouns: string;
  website: string;
  department: string;
  jobTitle: string;
  dateOfBirth: '2022-12-29T17:05:13.626Z';
  addressLine: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  facebook: string;
  linkedIn: string;
  tweeter: string;
  instagram: string;
  youTube: string;
  snapchat: string;
  whatsApp: string;
  skype: string;
  telegram: string;
}

export enum CardAppNames {
  BusinessApp = 'BusinessCard',
  LeadApp = 'LeadApp',
  ReviewApp = 'ReviewApp',
  PhoneQRApp = 'PhoneQRApp'
}

export interface AccountSignUp {
  businessName: string;
  email: string;
  password: string;
  location: string;
  terms: boolean;
  businessType: string;
  businessCategory: string;
  phone: string;
}

export interface NfcCodeMap {
  nfcCode: string;
  cardId?: number;
  cardAppId?: number;
  isActive: boolean;
  remark?: string;
  groupName?: string;
  mapType?: string;
  mapTo?: string;
  cardApp?: CardApp;
  cardName?: string;
  card?: DigitalCard;
}

export interface LeadComment {
  id: string;
  leadId: string;
  comment: string;
  attachement: string;
  createdOn: Date;
  createdBy: string;
}

export interface ReviewComment {
  id: string;
  selfReviewId: string;
  comment: string;
  attachement: string;
  createdOn: Date;
  createdBy: string;
}

export type LeadStatusType =
  | 'Open'
  | 'Completed'
  | 'InProgress'
  | 'Lost'
  | 'Won';
export type ReviewStatusType = 'Open' | 'InProgress' | 'Close';

export interface NotificationSetting {
  leadEmailSetting?: LeadEmailSetting;
  reviewEmailSetting?: ReviewEmailSetting;
}

export interface LeadEmailSetting {
  leadCreateMailToOwner?: boolean;
  leadCreateMailToMemberIds?: number[];
  leadStatusChangeMailToOwner?: boolean;
  leadStatusChangeMailToMemberIds?: number[];
  leadNoStatusChangeReminderInDay?: number;
  leadNoStatusChangeMailToOwner?: boolean;
  leadNoStatusChangeMailToMemberIds?: number[];
}

export interface ReviewEmailSetting {
  reviewReceiveMailToOwner?: boolean;
  reviewReceiveMailToMemberIds?: number[];
  reviewStatusChangeMailToOwner?: boolean;
  reviewStatusChangeMailToMemberIds?: number[];
  reviewNoStatusChangeReminderInDay?: number;
  reviewNoStatusChangeMailToOwner?: boolean;
  reviewNoStatusChangeMailToMemberIds?: number[];
}

export interface PaymentIntentResult {
  clientSecret: string;
  productId: string;
  productName: string;
  productDesc: string;
  productPrice: number;
}

export interface AccountSignUpStatus {
  status: string;
  statusDesc: string;
}
export interface ActiveSubscription {
  id: string;
  clientAccountId: number;
  status: 'Active' | 'Inactive';
  subscription: {
    name: string;
    id: string;
  };
  permission: {
    ocrEnabled: boolean;
    maxIndividualCard: number;
    maxBusinesCard: number;
    maxContact: number;
    qrMobileWidget: boolean;
    notification: {
      leadEmailEnabled: boolean;
      reviewEmailEnabled: boolean;
    };
  };
}
