import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import PageHeader from './PageHeader';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Lead } from 'src/services/ezzyEvents/apiService/responseModel';
import {
  QKEY,
  getLeadById,
  getRefData,
  saveLead
} from 'src/services/query-client';
import { LeadDto } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import readQueryString from 'src/utility/readQueryString';
import * as Yup from 'yup';
import { parseApiError } from '../../../utility/parseToApiErrorMessage';
import LeadContactCard from './lead-contact-card';
import LeadInfoCard from './lead-info-card';

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateLead() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: leadId } = useParams();

  const [isSubmit, setIsSubmit] = useState(false);
  const [validationError, setValidationError] = useState('');
  const isEdit = Boolean(leadId && leadId.length);

  const { user } = useSelector((state: RootState) => state.app);

  //Queries
  const {
    data: { data: accountRefData } = {},
    isError,
    error,
    isLoading: isRefDataLoading
  } = useQuery('ref-data', getRefData);

  const {
    data: { data: model } = {},
    isLoading,
    refetch: refetchById
  } = useQuery([QKEY.Leads, leadId], (k) => getLeadById(leadId), {
    enabled: isEdit
  });

  const {
    mutate: save,
    isError: isSaveError,
    error: errorOnSave,
    isLoading: isSaving
  } = useMutation((e: LeadDto) => saveLead(e, leadId), {
    onSuccess: (o) => onSaveSuccess(o.data)
  });

  //----Queries

  useEffect(() => {}, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (isSaveError) {
    const msg = parseApiError(
      errorOnSave,
      `Failed to ${!isEdit ? 'create' : 'update'} lead`
    );
    enqueueSnackbar(t(msg), { variant: 'error' });
  }

  const onSaveSuccess = async (updatedModel: Lead) => {
    enqueueSnackbar(
      t(`Lead was ${!isEdit ? 'created' : 'updated'} successfully`),
      { variant: 'success' }
    );
    if (!isEdit) navigate('/app/lead/edit/' + updatedModel.id);
    else {
      // appQueryClient.setQueryData([QKEY.Leads, leadId], updatedModel);
      refetchById();
    }
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const handleBack = (): void => {
    return navigate(readQueryString()?.ret || `/app/lead/list`);
  };

  // if (isLoading || isRefDataLoading) {
  //   return <LinearProgress />;
  // }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          contactFirstName: model?.contactFirstName || '',
          contactMiddleName: model?.contactMiddleName || '',
          contactLastName: model?.contactLastName || '',
          contactEmail: model?.contactEmail || '',
          contactNumber: model?.contactNumber || '',
          contactAddress: model?.contactAddress || '',
          remark: model?.remark || '',
          tags: model?.tags || [],
          teamMemberId: model?.teamMemberId || '',
          // profileId: model?.profile?.id || '',
          contactId: model?.contact?.id || '',
          liCyStage: model?.liCyStage || '',
          saveAsContact: true
        }}
        validationSchema={Yup.object().shape({
          contactFirstName: Yup.string().max(250),
          contactMiddleName: Yup.string().max(250),
          contactLastName: Yup.string().max(250),
          contactEmail: Yup.string().when('contactId', {
            is: (o) => !o,
            then: Yup.string()
              .max(250, 'Email must be at most 20 characters')
              .email('Email must be a valid email')
          }),
          contactNumber: Yup.string().when('contactId', {
            is: (o) => !o,
            then: Yup.string().max(
              20,
              'Contact number must be at most 20 digits'
            )
          }),
          contactAddress: Yup.string().max(250),
          remark: Yup.string().max(1000).required('Remark is required')
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          let valError = '';

          if (!_values.contactId) {
            if (
              !_values.contactEmail &&
              !_values.contactNumber &&
              !_values.contactFirstName &&
              !_values.contactLastName
            ) {
              valError =
                "Lead's contact information is missing. Either select existing contact or enter contact email or contact number or first name or last name";
            }
          }
          setValidationError(valError);
          if (valError) return;

          const updatedModel: LeadDto = {
            ..._values,
            source: {
              code: user.context.appName,
              value: 'Manual Create'
            }
          };

          save(updatedModel);
          setStatus({ success: true });
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader
                        isEdit={isEdit}
                        contactId={leadId}
                        onBack={handleBack}
                      />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <LeadInfoCard
                      // profiles={profiles}
                      // members={members}
                      accountRefData={accountRefData}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LeadContactCard isEdit={isEdit} />
                  </Grid>

                  {/*grs-grid-item <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                  ></Grid> */}

                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            component={RouterLink}
                            variant="outlined"
                            to={`/${location.pathname.split('/')[1]}/lead/list`}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{ ml: 2 }}
                            type="submit"
                            onClick={() => onSaveClick()}
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaving ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={isLoading || isSubmitting || isSaving}
                          >
                            Save
                          </Button>
                          {validationError && (
                            <FormHelperText error={true}>
                              {validationError}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateLead;
