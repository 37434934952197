import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import oAuth from '../../../../../auth-service';
import { RootState } from '../../../../../store';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 0.5)};
    height: ${theme.spacing(6)};
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.app.user);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const roleName = user.roles.map((o) => o.roleName).join(',');

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      setBackdropOpen(true);
      handleClose();
      await oAuth.logout();
      oAuth.redirectToHome();
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfileSetting = () => {
    handleClose();
    navigate('/app/setting');
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {mobile && backdropOpen ? (
          <CircularProgress color="inherit" />
        ) : (
          <Avatar variant="rounded" alt={user?.userName} />
        )}
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          {backdropOpen ? (
            <CircularProgress color="inherit" />
          ) : (
            <UserBoxText>
              <UserBoxLabel variant="body1">{user?.userName}</UserBoxLabel>
              {roleName && (
                <UserBoxDescription variant="body2">
                  {roleName}
                </UserBoxDescription>
              )}
            </UserBoxText>
          )}
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user?.userName} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.userName}</UserBoxLabel>
            {roleName && (
              <UserBoxDescription variant="body2">
                {roleName}
              </UserBoxDescription>
            )}
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          <MenuItem onClick={handleProfileSetting}>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Profile settings')}
            />
            <Box display="flex" alignItems="center">
              <ChevronRightTwoToneIcon
                sx={{
                  ml: 1,
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8
                }}
              />
            </Box>
          </MenuItem>
        </MenuListWrapperPrimary>
        <Divider />
        <Box m={1}>
          <Button
            disabled={backdropOpen}
            color="primary"
            fullWidth
            onClick={handleLogout}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
