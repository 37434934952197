import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactSelector from 'src/components/contact-selector';
import WarnConfirm from 'src/components/warn-confirm';
import { Address, Contact } from 'src/services/apiService/response-models';
import { LeadDto } from 'src/services/query-client/dto';
import { addressToText } from 'src/utility/addressToText';

function LeadContactCard(props: { isEdit: boolean }) {
  const { t }: { t: any } = useTranslation();
  const { values, setFieldValue } = useFormikContext<LeadDto>();

  const [selectedContact, setSelectedContact] = useState(null);
  const [warnOpen, setWarnOpen] = useState(false);

  const onContactSelect = (e: Contact) => {
    setSelectedContact(e);
    setFieldValue('contactId', e?.id || '');

    //before overwritting manually entered values
    if (selectedContact == null && e != null) {
      if (
        values.contactEmail ||
        values.contactNumber ||
        values.contactFirstName ||
        values.contactMiddleName ||
        values.contactLastName ||
        values.contactAddress
      ) {
        setWarnOpen(true);
        return;
      }
    }
    onSelectConfirm(e);
  };

  const onSelectConfirm = (e: Contact) => {
    const adrString = (addr: Address[]) => {
      if (!addr || !addr.length) return '';
      return addressToText(addr[0]);
    };

    setFieldValue('contactEmail', e?.email || '');
    setFieldValue('contactNumber', e?.contactNumber || '');
    setFieldValue('contactFirstName', e?.firstName || '');
    setFieldValue('contactMiddleName', e?.middleName || '');
    setFieldValue('contactLastName', e?.lastName || '');
    setFieldValue('contactAddress', adrString(e?.addresses));
  };

  return (
    <Card>
      <CardHeader title={t("Lead's contact information")} />
      <Divider />
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <ContactSelector
              selectedId={values.contactId}
              helperText="Select an existing contact to associate to lead"
              onChange={onContactSelect}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          ></Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              disabled={!!values.contactId}
              name="contactEmail"
              variant="outlined"
              label={t('Email')}
              placeholder={t('Enter email here ...')}
              component={FmTextFiled}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              disabled={!!selectedContact}
              name="contactNumber"
              variant="outlined"
              label={t('Contact number')}
              placeholder={t('Enter phone here ...')}
              component={FmTextFiled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              name="contactFirstName"
              disabled={!!values.contactId}
              component={FmTextFiled}
              label="Contact first name *"
              placeholder={t('Enter here...')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              name="contactMiddleName"
              disabled={!!values.contactId}
              component={FmTextFiled}
              label="Contact middle name *"
              placeholder={t('Enter here...')}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              name="contactLastName"
              disabled={!!values.contactId}
              component={FmTextFiled}
              label="Contact last name *"
              placeholder={t('Enter here...')}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Field name="contactAddress">
              {({ field, form, meta }: FieldProps) => (
                <TextField
                  multiline
                  fullWidth
                  disabled={!!values.contactId}
                  rows={2}
                  label={t("Contact's address/location")}
                  placeholder={t('Enter here ...')}
                  value={field.value}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value);
                  }}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={6}>
            <Field name="saveAsContact">
              {({ field, form, meta }: FieldProps) => (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      name="saveAsContact"
                      color={!values.contactId ? 'primary' : 'secondary'}
                      disabled={!!values.contactId}
                      defaultChecked
                      onBlur={form.handleBlur}
                      onChange={(e) =>
                        form.setFieldValue(field.name, e.target.checked)
                      }
                      checked={field.value}
                    />
                  }
                  label={t('Save as contact')}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Box>
      <WarnConfirm
        open={warnOpen}
        onClose={() => setWarnOpen(false)}
        onConfirm={() => onSelectConfirm(selectedContact)}
        message="Are you sure, you want to select an existing contact?"
      />
    </Card>
  );
}

export default LeadContactCard;
