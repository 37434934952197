import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DiscountIcon from '@mui/icons-material/Discount';
import { default as EditTwoToneIcon } from '@mui/icons-material/EditTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { Label } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DeleteCardAction from 'src/components/DeleteCardAction';
import cdnService from 'src/services/cdnService';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import CreateEditPromotionsDialog from './Dialog/create-edit-promotions';

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(['all'])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const CardActions = styled(Box)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
  z-index: 7;
  display: flex;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);
const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

const BoxComposed = styled(Box)(
  () => `
    position: relative;
  `
);

const BoxComposedContent = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 7;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedImage = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    filter: grayscale(80%);
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const BoxComposedBg = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);
const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);
const BoxOverlineButton = styled(Box)(
  ({ theme }) => `
    text-align: center;
    position: relative;
    z-index: 7;

    .MuiButton-root {
        border-radius: 50px;
        height: ${theme.spacing(7.5)};
        margin-top: -${theme.spacing(3.75)};
        padding: ${theme.spacing(0, 4)};
        border: 3px solid ${theme.colors.alpha.white[100]};
    }
  `
);

function Promotions(props: { accountRefData: RefData }) {
  const groupNames = [];
  // props.accountRefData.contactGroups.map((o) => o.value);
  const [fieldActiveDiscount, metaActiveDiscount, helpersActiveDiscount] =
    useField('services.activeDiscount');
  const [fieldPromotions, metaPromotions, helpersPromotions] = useField(
    'services.promotions'
  );

  const [fieldPromoItem, metaPromoItem, helpersPromoItem] =
    useField('promoItem');
  const [visible, setVisible] = useState(false);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const { enqueueSnackbar } = useSnackbar();
  // const [discount, setDiscount] = useState(0);

  return (
    <>
      <CreateEditPromotionsDialog
        promoItem={fieldPromoItem.value}
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersPromoItem.setValue({});
        }}
        onSave={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersPromoItem.setValue({});
        }}
        editIndex={editIndex}
      />
      <Card>
        <CardHeader
          avatar={
            <AvatarCloud variant="rounded">
              <DiscountIcon />
            </AvatarCloud>
          }
          title={t('Promotions')}
          subheader={t(
            'Add special promotions, offers and discounts to your services.'
          )}
          action={
            <Button
              startIcon={<AddTwoToneIcon fontSize="small" />}
              onClick={() => {
                setOpen(true);
              }}
            >
              {'Add'}
            </Button>
          }
        />
        <Divider />
        <BoxComposed
          sx={{
            background: `${theme.colors.gradients.purple3}`
          }}
        >
          <CardActions>
            <Label>{t('New')}</Label>
          </CardActions>
          <BoxComposedBg
            sx={{
              opacity: 0.1,
              background: `${theme.colors.gradients.black1}`
            }}
          />
          <BoxComposedImage
            sx={{
              opacity: 0.1,
              backgroundImage: 'url("/static/3.jpg")'
            }}
          />
          <BoxComposedContent pb={9} pt={7}>
            <Typography
              textAlign="center"
              sx={{
                pb: 1
              }}
              variant="h3"
            >
              {t('Add a special discount for your customers')}
            </Typography>
            <Typography textAlign="center" fontWeight="normal" variant="h4">
              {t(
                "Offer a discount to clients who find and book you on ezzy events. With a deal you'll be able to attract more clients and have another way to track how they found your business!"
              )}
            </Typography>
          </BoxComposedContent>
        </BoxComposed>
        <BoxOverlineButton>
          <Button
            onClick={() => {
              helpersActiveDiscount.setValue(3);
            }}
            variant="contained"
            size="small"
            color="info"
          >
            {t('3%')}
          </Button>
          <Button
            onClick={() => {
              helpersActiveDiscount.setValue(5);
            }}
            variant="contained"
            size="small"
            color="success"
          >
            {t('5%')}
          </Button>
          <Button
            onClick={() => {
              helpersActiveDiscount.setValue(10);
            }}
            variant="contained"
            size="small"
            color="warning"
          >
            {t('10%')}
          </Button>
          <Button
            onClick={() => {
              helpersActiveDiscount.setValue(15);
            }}
            variant="contained"
            size="small"
            color="secondary"
          >
            {t('15%')}
          </Button>
          <Button
            onClick={() => {
              helpersActiveDiscount.setValue(20);
            }}
            variant="contained"
            size="small"
            color="error"
          >
            {t('20%')}
          </Button>
        </BoxOverlineButton>
        <Box p={3} textAlign="center">
          <FormControl>
            {/* <FormLabel id="demo-row-radio-buttons-group-label">
              Selected Discounts
            </FormLabel> */}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={fieldActiveDiscount.value}
              defaultValue={0}
              onChange={(event) => {
                helpersActiveDiscount.setValue(Number(event.target.value));
              }}
            >
              {!!fieldActiveDiscount.value && (
                <FormControlLabel
                  value={fieldActiveDiscount.value}
                  control={<Radio />}
                  label={`${fieldActiveDiscount.value}%`}
                />
              )}
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Not offering any discounts currently."
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box p={3}>
          <FieldArray name="services.promotions">
            {(arrayHelpers) => {
              return (
                <Grid
                  container
                  direction="row"
                  spacing={4}
                  marginTop={4}
                  marginBottom={4}
                >
                  {arrayHelpers.form.values?.services?.promotions.length > 0 &&
                    arrayHelpers.form.values?.services?.promotions.map(
                      (mn, index) => (
                        <Grid key={mn.name} item md={4} xs={12}>
                          <CardWrapper>
                            <Box
                              sx={{
                                position: 'relative',
                                zIndex: '2'
                              }}
                            >
                              <Box
                                px={2}
                                pt={2}
                                display="flex"
                                justifyContent="space-between"
                              >
                                <IconButton
                                  color="primary"
                                  sx={{
                                    p: 0.5
                                  }}
                                  onClick={() => {
                                    helpersPromoItem.setValue(mn);
                                    setEditIndex(index);

                                    setOpen(true);
                                  }}
                                >
                                  <EditTwoToneIcon />
                                </IconButton>
                              </Box>
                              <Box>
                                <Box textAlign={'center'}>
                                  <img
                                    width={'100%'}
                                    height={'150'}
                                    src={
                                      cdnService.toImageUrl(mn.imageUrl) ||
                                      '/static/images/no-image.png'
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box
                                p={2}
                                display="flex"
                                justifyContent={'center'}
                              >
                                <Box>
                                  <Box>
                                    <Typography variant="button">
                                      Name:<b>{mn.name}</b>
                                    </Typography>
                                    <Typography
                                      sx={{
                                        pt: 1
                                      }}
                                      variant="h6"
                                    >
                                      promotion type: {mn.type}
                                    </Typography>
                                    {mn.type == 'discount' && (
                                      <Typography
                                        sx={{
                                          pt: 1
                                        }}
                                        variant="h6"
                                      >
                                        discount: %{mn.discount}
                                      </Typography>
                                    )}
                                    <Typography
                                      sx={{
                                        pt: 1
                                      }}
                                      variant="h6"
                                    >
                                      valid till:{' '}
                                      {moment(mn.validTillDate).format(
                                        'MM-DD-YYYY'
                                      )}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Divider />
                              <DeleteCardAction
                                onDelete={() => {
                                  const arr = [...fieldPromotions.value];
                                  arr.splice(index, 1);
                                  helpersPromotions.setValue(arr);
                                }}
                              />
                            </Box>
                          </CardWrapper>
                        </Grid>
                      )
                    )}
                </Grid>
              );
            }}
          </FieldArray>
        </Box>
      </Card>
    </>
  );
}

export default Promotions;
