import {
  Autocomplete,
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  useTheme
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';

import {
  CodeItem,
  RefData
} from 'src/services/ezzyEvents/apiService/responseModel';
// import {
//   CodeItem,
//   Profile,
//   RefData,
//   TeamMember
// } from 'src/services/apiService/response-models';

function ReviewInfoCard(props: {
  // profiles: Profile[];
  // members: TeamMember[];
  accountRefData: RefData;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  // const profileItems = profilesToItems(props.profiles);
  // const memberItems = membersToItems(props.members);

  return (
    <Card>
      <CardHeader title={t('Review Information')} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={1}>
        <Grid item xs={12}>
          <Field name="remark">
            {({ field, form, meta }: FieldProps) => (
              <>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  label={t('Remark/description')}
                  placeholder={t('Enter here ...')}
                  value={field.value}
                  onChange={(e) => {
                    form.setFieldValue(field.name, e.target.value);
                  }}
                  onBlur={form.handleBlur}
                />
                {meta.error && (
                  <FormHelperText error={true}>{meta.error}</FormHelperText>
                )}
              </>
            )}
          </Field>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="top"
            sx={{ ml: 0 }}
            control={<span></span>}
            label={t('Associated profile (optional) ')}
          />

          <Field name="profileId">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                options={profileItems}
                getOptionLabel={(option: CodeItem | null) =>
                  option?.value || ''
                }
                isOptionEqualToValue={(option, value) =>
                  option?.code === value?.code
                }
                value={profileItems.find((o) => o.code == field.value) || {}}
                onChange={(event: any, newValue: CodeItem | null) => {
                  setFieldValue(field.name, newValue?.code);
                  setFieldTouched(field.name, true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Select a profile...')}
                  />
                )}
              />
            )}
          </Field>
        </Grid> */}
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="top"
            sx={{ ml: 0 }}
            control={<span></span>}
            label={t('Tag(s)')}
          />
          <Field name="tags">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                multiple
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(event: any, newValues: string[] | null) => {
                  const vals = newValues.filter((o) => o.trim().length);
                  setFieldValue(field.name, vals);
                  setFieldTouched(field.name, true);
                }}
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select tags...')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="top"
            sx={{ ml: 0 }}
            control={<span></span>}
            label={t('Lead life-cycle stage')}
          />
          <Field name="liCyStage">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                options={props.accountRefData?.leadLiCyStages || []}
                getOptionLabel={(option: CodeItem | null) =>
                  option?.value || ''
                }
                isOptionEqualToValue={(option, value) =>
                  option?.code === value?.code
                }
                value={
                  props.accountRefData?.leadLiCyStages.find(
                    (o) => o.code == field.value
                  ) || {}
                }
                onChange={(event: any, newValue: CodeItem | null) => {
                  setFieldValue(field.name, newValue?.code);
                  setFieldTouched(field.name, true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Select a review stage... ')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            labelPlacement="top"
            sx={{ ml: 0 }}
            control={<span></span>}
            label={t('Assign to')}
          />
          <Field name="teamMemberId">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                options={memberItems}
                getOptionLabel={(option: CodeItem | null) =>
                  option?.value || ''
                }
                isOptionEqualToValue={(option, value) =>
                  option?.code === value?.code
                }
                value={memberItems.find((o) => o.code == field.value) || {}}
                onChange={(event: any, newValue: CodeItem | null) => {
                  setFieldValue(field.name, newValue?.code);
                  setFieldTouched(field.name, true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Select a team member...')}
                  />
                )}
              />
            )}
          </Field>
        </Grid> */}
      </Grid>
    </Card>
  );
}

export default ReviewInfoCard;
