import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { Field, FieldProps, Formik, useField } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from 'src/services/query-client/dto';
import { RootState } from 'src/store';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import * as Yup from 'yup';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function CreateEditMenuDialog(props: {
  menuItem?: {
    name: string;
    desc: string;
    price: number;
    isActive: boolean;
    descRich: string;
  };
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  editIndex: number;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(props.editIndex >= 0);
  const [fieldMenus, metaMenus, helpersMenus] = useField('services.menus');
  const [model, setModel] = useState<MenuItem | null>(null);

  useEffect(() => {
    setOpen(props.isOpen);
    setModel(props.menuItem);
  }, [props.isOpen, props.menuItem]);

  const onSave = async (updatedModel: MenuItem) => {
    helpersMenus.setValue([...(fieldMenus?.value || []), updatedModel]);
    let isSuccess = true;
    try {
      setIsSaveInProgress(true);

      enqueueSnackbar(
        t(`Menu was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Menu`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Dialog open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {isEdit ? t(`Edit Menu`) : t('Add Menu')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update menu')
                : t('Fill in the fields below to create a new menu')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              name: model?.name || '',
              desc: model?.desc || '',
              descRich: model?.descRich || '',
              price: model?.price || '',
              isActive: isEdit
                ? model?.isActive === true
                  ? true
                  : false
                : true
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(t('The field is required')),
              desc: Yup.string().max(2550).required(t('The field is required')),
              price: Yup.number()
                .typeError(t('should be a number'))
                .positive(t('The price is invalid'))
                .required(t('The field is required'))
            })}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                const arr = [...fieldMenus?.value];
                if (props.editIndex >= 0) {
                  arr[props.editIndex] = _values;
                  // arr.splice(props.editIndex, 1);
                } else {
                  arr.push(_values);
                }

                helpersMenus.setValue(arr);
                var isSuccess = true;
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
                props.onSave();
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label={t('Menu name')}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field name="descRich">
                            {({ field, form, meta }: FieldProps) => (
                              <>
                                <EditorWrapper>
                                  <ReactQuill
                                    modules={{
                                      clipboard: {
                                        matchVisual: false
                                      }
                                    }}
                                    placeholder="Add your menu details"
                                    value={field.value}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) => {
                                      const text = editor.getText();
                                      const textRich = editor.getHTML();
                                      form.setFieldValue(field.name, textRich);
                                      form.setFieldValue('desc', text);
                                    }}
                                  />
                                </EditorWrapper>
                                {meta.touched &&
                                  (meta.error ? true : false) && (
                                    <FormHelperText
                                      error={
                                        meta.touched &&
                                        (meta.error ? true : false)
                                      }
                                    >
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </>
                            )}
                          </Field>
                          <FormHelperText>Menu Details</FormHelperText>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <TextField
                            multiline
                            error={Boolean(touched.desc && errors.desc)}
                            fullWidth
                            helperText={touched.desc && errors.desc}
                            label={t('Description')}
                            rows={3}
                            name="desc"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.desc}
                            variant="outlined"
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.price && errors.price)}
                            fullWidth
                            helperText={touched.price && errors.price}
                            label={t('Price')}
                            name="price"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.price}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            labelPlacement="start"
                            control={
                              <Switch
                                name="isActive"
                                // disabled={!isEdit}
                                color={isEdit ? 'primary' : 'secondary'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.isActive}
                              />
                            }
                            label={t('Is Active')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default CreateEditMenuDialog;
