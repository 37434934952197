import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loginWithGoogleSignon } from 'src/services/query-client';
import Logo from 'src/ui-bloom/components/Logo';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import useAuth from 'src/ui-bloom/hooks/useAuth';
import * as Yup from 'yup';
import oAuth from '../../auth-service';
import { login } from '../../services/apiService';
import useRefMounted from '../../ui-bloom/hooks/useRefMounted';
const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg'
};

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(0)};
`
);

const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[70]};
`
);

const ListItemTextWrapper = styled(ListItemText)(
  ({ theme }) => `
      color: ${theme.colors.primary.light};
`
);
const ListItemIconWrapper = styled(ListItemIcon)(
  ({ theme }) => `
      color: ${theme.colors.success.main};
      min-width: 32px;
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const returl = localStorage.getItem('ezzy-event-login-returl');

  const handleBack = () => {
    oAuth.goBack();
  };

  return (
    <>
      <Helmet>
        <title>Login - EzzyEvents</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <Box>
                <Box
                  sx={{
                    position: 'relative'
                  }}
                >
                  <img
                    height="100%"
                    width="100%"
                    src="/ezzy/images/bg-cover.png"
                  />
                </Box>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>

        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Box>
              <Logo />
            </Box>

            {returl && (
              <Box>
                <Tooltip arrow placement="top" title={t('Go back')}>
                  <IconButton onClick={handleBack} color="primary" sx={{}}>
                    <ArrowBackTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              <LoginForm />
              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t("Don't have an account, yet?")}
                </Typography>{' '}
                <Box display={{ xs: 'block', md: 'inline-block' }}>
                  <Link component={RouterLink} to="/app/sign-up">
                    <b>Sign up here</b>
                  </Link>
                </Box>
              </Box>

              <Box
                sx={{
                  textAlign: '-webkit-center'
                }}
              >
                <GoogleLogin
                  width={'100%'}
                  onSuccess={async (credentialResponse) => {
                    // console.log(credentialResponse);
                    let done = false;
                    try {
                      const res = await loginWithGoogleSignon(
                        credentialResponse.credential
                      );
                      if (res.data.token && !res.data?.redirectToSignup) {
                        oAuth.setLogInSuccess(res.data.token);
                        oAuth.setSSOLogInSuccess('');
                        done = true;
                      }
                      if (res.data.redirectToSignup) {
                        oAuth.setSSOLogInSuccess(credentialResponse.credential);
                        oAuth.redirectToSignup(res.data.user);
                      }
                    } catch (err) {
                      enqueueSnackbar(t('Invalid credentials'), {
                        variant: 'error'
                      });
                      console.error(err);
                    }
                    if (done) {
                      let requrl = localStorage.getItem(
                        'ezzy-event-login-requrl'
                      );
                      const returl = localStorage.getItem(
                        'ezzy-event-login-returl'
                      );
                      if (requrl) {
                        if (returl) {
                          //append returl as query string
                          requrl =
                            requrl +
                            (requrl.includes('?') ? '&' : '?') +
                            'returl=' +
                            encodeURIComponent(returl);
                        }
                        window.location.href = requrl;
                        localStorage.removeItem('ezzy-event-login-requrl');
                        localStorage.removeItem('ezzy-event-login-returl');
                      } else {
                        oAuth.redirectToHome();
                      }
                    }
                  }}
                  onError={() => {
                    console.log('Login Failed');
                    enqueueSnackbar(t('Invalid credentials'), {
                      variant: 'error'
                    });
                  }}
                  shape="rectangular"
                  useOneTap
                />
              </Box>
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

const LoginForm: FC = () => {
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const handlePwdVisiblity = () => {
    setShowPassword((prev) => !prev);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
        terms: Yup.boolean().oneOf(
          [true],
          t('You must agree to our terms and conditions')
        )
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        let done = false;
        try {
          const res = await login(values.email, values.password);
          oAuth.setLogInSuccess(res.data.token);
          done = true;
        } catch (err) {
          console.error(err);
          setSubmitting(false);
          enqueueSnackbar(t('Invalid credentials'), { variant: 'error' });
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
          }
        }
        if (done) {
          let requrl = localStorage.getItem('ezzy-event-login-requrl');
          const returl = localStorage.getItem('ezzy-event-login-returl');
          if (requrl) {
            if (returl) {
              //append returl as query string
              requrl =
                requrl +
                (requrl.includes('?') ? '&' : '?') +
                'returl=' +
                encodeURIComponent(returl);
            }
            window.location.href = requrl;
            localStorage.removeItem('ezzy-event-login-requrl');
            localStorage.removeItem('ezzy-event-login-returl');
          } else {
            oAuth.redirectToHome();
          }
          if (isMountedRef.current) {
            setStatus({ success: true });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            helperText={touched.email && errors.email}
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            helperText={touched.password && errors.password}
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onMouseDown={handleMouseDownPassword}
                    onClick={handlePwdVisiblity}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box
            alignItems="center"
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="space-between"
          >
            <Box display={{ xs: 'block', md: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.terms}
                    name="terms"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body2">
                      {t('I accept the')}{' '}
                      <Link component="a" href="#">
                        {t('terms and conditions')}
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
            </Box>
            <Link component={RouterLink} to="/app/forgot-password">
              <b>{t('Lost password?')}</b>
            </Link>
          </Box>

          {Boolean(touched.terms && errors.terms) && (
            <FormHelperText error>{errors.terms}</FormHelperText>
          )}

          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Sign in')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginCover;
