import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Field, FieldProps, useField } from 'formik';
import { TextField as FmTextFiled } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

function PersonalDetails(props: { accountRefData: RefData }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const [fieldPronouns, metaPronouns, helpersPronouns] = useField('pronouns');

  return (
    <Card>
      <CardHeader title={t("Contact's Personal Details")} />
      <Divider />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
          <Grid item md={3} xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="address-state-select-filled-label">
                Title
              </InputLabel>
              <Select
                name="pronouns"
                labelId="pronouns-select"
                id="pronouns-select"
                value={fieldPronouns.value}
                onChange={(_event) => {
                  helpersPronouns.setValue(_event.target.value);
                }}
              >
                <MenuItem value="">
                  <em>Select...</em>
                </MenuItem>
                {props.accountRefData.nameTitles.map((o) => (
                  <MenuItem key={o.code} value={o.value}>
                    {o.value}
                  </MenuItem>
                ))}
              </Select>
              {/*{touched.stateCode && (errors.stateCode ? true : false) &&*/}
              {/*    <FormHelperText error={touched.stateCode && (errors.stateCode ? true : false)}>{errors.stateCode}</FormHelperText >}*/}
            </FormControl>
          </Grid>
          <Grid item md={9} xs={12}>
            <Field
              fullWidth
              name="firstName"
              component={FmTextFiled}
              label="First name *"
              placeholder={t('first name here...')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              name="middleName"
              component={FmTextFiled}
              label="Middle name"
              placeholder={t('middle name here...')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              name="lastName"
              component={FmTextFiled}
              label="Last name *"
              placeholder={t('last name here...')}
            />
          </Grid>
          <Grid item xs={12}>
            <Field name="descriptionRich">
              {({ field, form, meta }: FieldProps) => (
                <EditorWrapper>
                  <ReactQuill
                    placeholder="About contact"
                    value={field.value}
                    onChange={(content, delta, source, editor) => {
                      const text = editor.getText();
                      const textRich = editor.getHTML();
                      form.setFieldValue(field.name, textRich);
                      form.setFieldValue('description', text);
                    }}
                  />
                </EditorWrapper>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="dateOfBirth">
              {({ field, form, meta }: FieldProps) => (
                <DesktopDatePicker
                  label="Date of birth"
                  inputFormat="MM/dd/yyyy"
                  onChange={(newValue: Date | null) =>
                    form.setFieldValue(field.name, newValue)
                  }
                  value={field.value || null}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Card>
  );
}

export default PersonalDetails;
