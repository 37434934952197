import DiscountIcon from '@mui/icons-material/Discount';
import { default as EditTwoToneIcon } from '@mui/icons-material/EditTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { FieldArray, useField } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DeleteCardAction from 'src/components/DeleteCardAction';
import cdnService from 'src/services/cdnService';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';
import CreateEditEventsDialog from './Dialog/create-edit-events';

const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const BoxWrapper = styled(Box)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      border-radius: ${theme.general.borderRadius};
      text-align: center;
      width: ${theme.spacing(9)};
      height: ${theme.spacing(9)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing(1.5)};
      flex-shrink: 0;
      transition: ${theme.transitions.create(['background'])};
`
);

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      border-radius: 0;
      padding: ${theme.spacing(2)};
      
      &:hover {
        .MuiDate-wrapper {
            background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        }
      }
`
);

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        border-radius: 100px;
        transition: ${theme.transitions.create(['all'])};

        &:hover {
          color: ${theme.colors.alpha.black[100]};
          background: ${theme.colors.primary.lighter};
        }
`
);

const CardActions = styled(Box)(
  ({ theme }) => `
  position: absolute;
  right: ${theme.spacing(2)};
  bottom: ${theme.spacing(2)};
  z-index: 7;
  display: flex;
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);
const LabelWrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.info.main};
  color: ${theme.palette.info.contrastText};
  text-transform: uppercase;
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  line-height: 23px;
  height: 22px;
  padding: ${theme.spacing(0, 1.2)};
  border-radius: 50px;
`
);

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

const BoxComposed = styled(Box)(
  () => `
    position: relative;
  `
);

const BoxComposedContent = styled(Box)(
  ({ theme }) => `
    position: relative;
    z-index: 7;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
    
  `
);

const BoxComposedImage = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    filter: grayscale(80%);
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);

const BoxComposedBg = styled(Box)(
  () => `
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  `
);
const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);
const BoxOverlineButton = styled(Box)(
  ({ theme }) => `
    text-align: center;
    position: relative;
    z-index: 7;

    .MuiButton-root {
        border-radius: 50px;
        height: ${theme.spacing(7.5)};
        margin-top: -${theme.spacing(3.75)};
        padding: ${theme.spacing(0, 4)};
        border: 3px solid ${theme.colors.alpha.white[100]};
    }
  `
);

function Events(props: { accountRefData: RefData }) {
  const eventTypes = props.accountRefData.eventTypes;

  const [fieldEvents, metaEvents, helpersEvents] = useField('services.events');

  const [fieldEventItem, metaEventItem, helpersEventItem] =
    useField('eventItem');
  const [visible, setVisible] = useState(false);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <CreateEditEventsDialog
        eventItem={fieldEventItem.value}
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersEventItem.setValue({});
        }}
        onSave={() => {
          setOpen(false);
          setEditIndex(undefined);
          helpersEventItem.setValue({});
        }}
        editIndex={editIndex}
        eventTypes={eventTypes}
      />
      <Card>
        <CardHeader
          avatar={
            <AvatarCloud variant="rounded">
              <DiscountIcon />
            </AvatarCloud>
          }
          title={t('Events')}
          subheader={t('Add details about upcoming events you are hosting.')}
          // action={
          //   <Button
          //     startIcon={<AddTwoToneIcon fontSize="small" />}
          //     onClick={() => {
          //       setOpen(true);
          //     }}
          //   >
          //     {'Add'}
          //   </Button>
          // }
        />
        <Divider />
        <BoxComposed
          sx={{
            background: `${theme.colors.gradients.orange3}`
          }}
        >
          <BoxComposedBg
            sx={{
              opacity: 0.1,
              background: `${theme.colors.gradients.black1}`
            }}
          />
          <BoxComposedImage
            sx={{
              opacity: 0.1,
              backgroundImage: 'url("/static/3.jpg")'
            }}
          />
          <BoxComposedContent pb={9} pt={7}>
            <Typography
              textAlign="center"
              sx={{
                pb: 1
              }}
              variant="h3"
            >
              {t(
                'Please provide additional information regarding the upcoming events you are hosting.'
              )}
            </Typography>
            <Typography textAlign="center" fontWeight="normal" variant="h4">
              {t(
                'Hosting events entices prospective clients to attend and gain further insights into your business.'
              )}
            </Typography>
          </BoxComposedContent>
        </BoxComposed>

        {!visible && (
          <Card>
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <AvatarPrimary
                  sx={{
                    mr: 1.5
                  }}
                >
                  <DateRangeTwoToneIcon />
                </AvatarPrimary>
                <Typography variant="h4">{t('Upcoming Events')}</Typography>
              </Box>
              <Tooltip placement="top" arrow title={t('Create new event')}>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  sx={{
                    alignSelf: 'center'
                  }}
                  color="primary"
                >
                  <ControlPointTwoToneIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Divider />
            <List disablePadding component="div">
              {fieldEvents.value
                .filter((ev) =>
                  moment(ev.startDate).isSameOrAfter(moment(), 'day')
                )
                .map((ev) => (
                  <div key={ev.startDate + ev.name}>
                    <ListItemWrapper alignItems="flex-start">
                      <BoxWrapper className="MuiDate-wrapper">
                        <Box>
                          <Typography variant="h2" color="text.primary">
                            {moment(ev.startDate).date()}
                          </Typography>
                          <Typography variant="subtitle2">
                            {t(moment(ev.startDate).format('MMM'))}
                          </Typography>
                        </Box>
                      </BoxWrapper>
                      <Box>
                        <Typography
                          variant="h4"
                          sx={{
                            pt: 1
                          }}
                          color="text.primary"
                          noWrap
                          gutterBottom
                        >
                          {t(ev.name)}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          {t(ev.description)}
                        </Typography>
                      </Box>
                    </ListItemWrapper>
                    <Divider />
                  </div>
                ))}
            </List>
            <Divider />
            <Box p={2}>
              <Button
                onClick={() => {
                  setVisible(true);
                }}
                variant="outlined"
                size="small"
                endIcon={<ArrowForwardTwoToneIcon fontSize="small" />}
              >
                {t('View all')}
              </Button>
            </Box>
          </Card>
        )}

        {visible && (
          <Box p={3}>
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <AvatarPrimary
                  sx={{
                    mr: 1.5
                  }}
                >
                  <DateRangeTwoToneIcon />
                </AvatarPrimary>
                <Typography variant="h4">{t('All Events')}</Typography>
              </Box>
              <Tooltip placement="top" arrow title={t('Create new event')}>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  sx={{
                    alignSelf: 'center'
                  }}
                  color="primary"
                >
                  <ControlPointTwoToneIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Divider />
            <FieldArray name="services.events">
              {(arrayHelpers) => {
                return (
                  <Grid
                    container
                    direction="row"
                    spacing={4}
                    marginTop={4}
                    marginBottom={4}
                  >
                    {arrayHelpers.form.values?.services?.events.length > 0 &&
                      arrayHelpers.form.values?.services?.events.map(
                        (mn, index) => (
                          <Grid key={mn.name} item md={4} xs={12}>
                            <CardWrapper>
                              <Box
                                sx={{
                                  position: 'relative',
                                  zIndex: '2'
                                }}
                              >
                                <Box
                                  px={2}
                                  pt={2}
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <IconButton
                                    color="primary"
                                    sx={{
                                      p: 0.5
                                    }}
                                    onClick={() => {
                                      helpersEventItem.setValue(mn);
                                      setEditIndex(index);

                                      setOpen(true);
                                    }}
                                  >
                                    <EditTwoToneIcon />
                                  </IconButton>
                                </Box>
                                <Box>
                                  <Box textAlign={'center'}>
                                    <img
                                      width={'100%'}
                                      height={'150'}
                                      src={
                                        cdnService.toImageUrl(mn.imageUrl) ||
                                        '/static/images/no-image.png'
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  p={2}
                                  display="flex"
                                  justifyContent={'center'}
                                >
                                  <Box>
                                    <Box>
                                      <Typography variant="button">
                                        Name:<b>{mn.name}</b>
                                      </Typography>
                                      <Typography
                                        sx={{
                                          pt: 1
                                        }}
                                        variant="h6"
                                      >
                                        Event type: {mn.type}
                                      </Typography>

                                      <Typography
                                        sx={{
                                          pt: 1
                                        }}
                                        variant="h6"
                                      >
                                        from:{' '}
                                        {moment(mn.startDate).format(
                                          'MM-DD-YYYY'
                                        )}
                                        {' to '}
                                        {moment(mn.endDate).format(
                                          'MM-DD-YYYY'
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Divider />
                                <DeleteCardAction
                                  onDelete={() => {
                                    const arr = [...fieldEvents.value];
                                    arr.splice(index, 1);
                                    helpersEvents.setValue(arr);
                                  }}
                                />
                              </Box>
                            </CardWrapper>
                          </Grid>
                        )
                      )}
                  </Grid>
                );
              }}
            </FieldArray>
            <Divider />
            <Box p={2}>
              <Button
                onClick={() => {
                  setVisible(false);
                }}
                variant="outlined"
                size="small"
                endIcon={<ArrowForwardTwoToneIcon fontSize="small" />}
              >
                {t('Upcoming Events')}
              </Button>
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
}

export default Events;
