import { Card, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { TextField as FmTextFiled } from 'formik-mui';

import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { RefData } from 'src/services/ezzyEvents/apiService/responseModel';

function PriceRange(props: { accountRefData: RefData }) {
  const amenitiesNames = props.accountRefData.amenities.map((o) => o.value);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Card>
        <CardHeader title={t('Price Range')} />
        <Divider />

        <Grid sx={{ padding: theme.spacing(2) }} container spacing={1}>
          <Grid item md={4} xs={12}>
            <Field
              fullWidth
              name="services.guestCapacity"
              component={FmTextFiled}
              label="Guest Capacity"
              placeholder={t('guest capacity here...')}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Field
              fullWidth
              name="services.priceRange.min"
              component={FmTextFiled}
              label="Minimum price($)"
              placeholder={t('minimum price here...')}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Field
              fullWidth
              name="services.priceRange.max"
              component={FmTextFiled}
              label="Maximum price($)"
              placeholder={t('maximum price here...')}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default PriceRange;
